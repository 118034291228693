<template>
<div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc">
            <span style="color:#777; font-size:18px;padding:10px">Edit Admited Student Info</span>
        </div>
    </div>
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
        <v-alert style="color:#ff735e"> <strong>UNDERGRADUATE REGULAR 2023/2024 ADMISSION WINDOW</strong> <v-btn x-small style="margin-left:20px">Switch Admission Session</v-btn></v-alert>                        
        <v-row v-if="success">
            <div style="margin:auto; padding:20px; margin-top:100px" class="col-md-5">
                <v-card elevation="3" height="300">
                    <v-card-title class="text-h7" style="color:#666">
                        Find Admited Student
                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>

                    <v-col cols="9" style="margin:auto;padding-top:45px" pa-4>
                        <v-text-field outlined label="Student Identity" v-model="regnumber" dense></v-text-field>
                        <v-btn color="primary" @click="getStudentInfo" elevation='2' class="col-md-12" :disabled="regnumber==''">Find</v-btn>
                    </v-col>
                </v-card>
            </div>
        </v-row>

     
        <v-card v-else>
          <v-card-title class="text-h7" style="color:#666">
            Admited Student Information
          </v-card-title>
          <v-divider style="margin-top:0px"></v-divider>
        <v-col class="col-sm-0 col-md-2 col-lg-2"></v-col>
        <v-col class="col-sm-12 col-md-8 col-lg-6">
          <v-form>
            <v-row class="pa-1">
              <v-col cols="6" class="py-0">
                <label for="">First Name</label>
                <v-text-field
                  outlined
                  label="Enter First Name"
                  v-model="firstname"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Surname</label>
                <v-text-field
                  outlined
                  label="Enter Last Name"
                  v-model="surname"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Other Names</label>
                <v-text-field
                  outlined
                  label="Enter Other Names"
                  v-model="othernames"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Email</label>
                <v-text-field
                  outlined
                  label="Enter Email"
                  v-model="email"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Date of Birth</label>
                <v-text-field
                  outlined
                  label="Enter DOB"
                  v-model="dob"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Gender</label>

                <v-text-field
                  outlined
                  label="Enter sex"
                  v-model="gender"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Nationality</label>
                <v-text-field
                  outlined
                  label="Enter Nationality"
                  v-model="nationality"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">State</label>
                <v-text-field
                  outlined
                  label="Enter State"
                  v-model="state"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">LGA</label>
                <v-text-field
                  outlined
                  label="Enter LGA"
                  v-model="lga"
                ></v-text-field>
              </v-col>
              <v-col class="py-0" cols="6">
                <label for="">Town</label>
                <v-text-field
                  outlined
                  label="Enter Town"
                  v-model="town"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="py-0">
                <label for="">Residential Address</label>
                <span>
                  <v-text-field
                    outlined
                    label="Enter Residential Address"
                    v-model="residential_address"
                  ></v-text-field>
                </span>
              </v-col>
            </v-row>
          </v-form>
          <div class="my-10 button_procceed">
            <v-btn
              block
              style="background: #006794; color: #fff; height: 50px "
              type="submit"
              @click="update"
            >
              Update Record
            </v-btn>
          </div>
        </v-col>
        <v-col class="col-sm-0 col-md-2 col-lg-2"></v-col>
        </v-card>
    </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  data() {
    return {
      success: true,
      surname: "",
      firstname: "",
      othernames: "",
      regnumber: "",
      email: "",
      dob: "",
      gender: "",
      nationality: "",
      state: "",
      lga: "",
      town: "",
      residential_address: "",
      next_of_kin: "",
    };
  },

  methods: {
    async update() {
      try {
        const data = {
          surname: this.surname,
          firstname: this.firstname,
          othernames: this.othernames,
          email: this.email,
          dob: this.dob,
          gender: this.gender,
          nationality: this.nationality,
          state: this.state,
          lga: this.lga,
          town: this.town,
          residential_address: this.residential_address,
          next_of_kin: this.next_of_kin,
        };
        const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT +'/changeClearnceData'
        const res = await axios.post(
          url,
          data
        );
        if (res.data.code == "00") {
          this.success = true;
          this.surname = "";
          this.firstname = "";
          this.othernames = "";
          this.email = "";
          this.dob = "";
          this.gender = "";
          this.nationality = "";
          this.state = "";
          this.lga = "";
          this.town = "";
          this.residential_address = "";
          this.next_of_kin = "";
          return Swal.fire({
            icon: "success",
            title: "Sucessful",
          });
        } else {
          return Swal.fire({
            icon: "error",
            title: "Something went wrong",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getStudentInfo() {
      try {
        if (!this.regnumber)
          return Swal.fire({ icon: "error", title: "Please Enter Reg Number" });
                  const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT +'/find_admited'
        const res = await axios.post(
         url,
          {
                    entryData: this.regnumber,
                    year: '2023_2024'
                }
        );
        if (res.data.code == "00") {
          this.success = false;
          this.regnumber = "";
          this.surname = res.data.message.surname;
          this.firstname = res.data.message.name;
          this.othernames = res.data.message.othername;
          this.email = res.data.message.email;
          this.dob = res.data.message.dob;
          this.gender = res.data.message.gender;
          this.nationality = res.data.message.nationality;
          this.state = res.data.message.state;
          this.lga = res.data.message.lga;
          this.town = res.data.message.town;
          this.residential_address = res.data.message.resident;
          this.next_of_kin = res.data.message.next_of_kin;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button_procceed {
  text-align: right;

  button {
    justify-content: center;
    align-items: center;
    padding: 15px;
    width: 335px;
    height: 45px;

    background: #006794;
    border-radius: 3px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;

    align-items: center;
    text-align: center;

    color: #ffffff;
  }
}

.waec_jamb {
  h4 {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 114%;
    color: #4f4f4f;
  }
}

.list_item {
  list-style-type: none;
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 114%;
  margin: 10px 0;
  color: #4f4f4f;
}

.list_item2 {
  list-style-type: none;
  margin: 7.5px 0;

  input {
    align-items: center;
    padding: 12px;
    width: 300px;
    height: 40px;
    border-radius: 7px;
    outline: 1px solid #ccc;
    background: #ffffff;
    border: 0.5px solid #4f4f4f;
    box-sizing: border-box;
    border-radius: 4px;
  }
}

.text {
  margin: 10px 0;

  p {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #4f4f4f;
  }
}

.box {
  width: 100%;
  height: auto;
  background: #ffffff;
}

.txt {
  width: 100%;
  height: 44px;
  background: #d6ecf5;

  p {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #006794;
  }
}
</style>

<template>
  <div >
      <div class="col_table">
                <v-card style="min-height:1030px">
                    <v-card-title>
                        <div class="cardTableHeader">
                      Departmental e-Clearance Analysis
                    </div>
                        <v-spacer></v-spacer>
                       

                        <!-- <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <button v-bind="attrs" v-on="on" @click="csvExport(csvData)" class="download_b" type="submit"> <span style="margin-right: 4px"></span> Download</button>
                            </template>
                            <span>Download The Record</span>
                        </v-tooltip> -->
                        <!-- <v-pagination v-model="studetList" :length="studetListpageCount"></v-pagination> -->
                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>

                     <v-row
        class="fill-height"
        align-content="center"
        justify="center" v-if="!dataLoaded"
      >
    
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
         Sorting Departmental Clearance Data
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="deep-purple accent-1"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <div v-if="dataLoaded" class="px-5">
        <v-spacer></v-spacer>
        <div class="row" style="margin:0;padding:0">
<div class="col-md-6">

</div>
<div class="col-md-6">
    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Department" single-line hide-details style="margin-bottom:10px"></v-text-field>

</div>
        </div>
                    <v-data-table :page.sync="studetList" @page-count="studetListpageCount = $event" :headers="headers" :items="desserts" items-per-page="16" :search="search" >
                    
                    </v-data-table></div>
                </v-card>
            </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
data(){
    return{
        dataLoaded:false,
        dataDepartment:[],
        admittedData:[],
        clearanceData:[],
        search:'',
          headers: [{
                    text: 'Department',
                    align: 'start',
                    value: 'department',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Admitted students',
                    value: 'numberAdmited',
                    class: 'grey lighten-4'
                },{
                    text: 'Accepted Admission',
                    value: 'numberAccepted',
                    class: 'grey lighten-4'
                },
                
                {
                    text: 'Cleared students',
                    value: 'numberCleared',
                    class: 'grey lighten-4'
                },
            ],
            desserts: [{
                    department: 'Frozen Yogurt',
                    admitted: '190000',
                    cleared: '123889',
                    uncleard: '50228',
                },
                {
                    department: 'Frozen Yogurt',
                    admitted: '190000',
                    cleared: '123889',
                    uncleard: '50228',
                },
                {
                    department: 'Frozen Yogurt',
                    admitted: '190000',
                    cleared: '123889',
                    uncleard: '50228',
                },
                {
                    department: 'Frozen Yogurt',
                    admitted: '190000',
                    cleared: '123889',
                    uncleard: '50228',
                },
                {
                    department: 'Frozen Yogurt',
                    admitted: '190000',
                    cleared: '123889',
                    uncleard: '50228',
                },
                {
                    department: 'Frozen Yogurt',
                    admitted: '190000',
                    cleared: '123889',
                    uncleard: '50228',
                },
            ],

    }
},

    async beforeMount() {
        this.fetchData();
    },
mounted(){

},
 methods:{
    async fetchData() {
            const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT + '/getDeptClearAnalysis';
            const admissionAnalysis = await axios({
                method: 'POST',
                url: url,
                data: {
                    staffId:`${this.$logedIndataId}`,
                    session: '2022_2023'
                }
            }).catch(e => {
                console.log(e);
            })
            if (admissionAnalysis.data.code == '00') {
                console.log(admissionAnalysis.data.data)
                this.desserts = admissionAnalysis.data.data
                this.dataLoaded = true
//                 this.dataDepartment = admissionAnalysis.data.dataDept
//                 this.admittedData = admissionAnalysis.data.dataAdmited
//                 this.clearanceData = admissionAnalysis.data.dataClearance
// const departmentCounts=[];
//                 this.dataDepartment.forEach( element => {
// const maped =   this.admittedData.filter(student => {
//   if(student.department != null){
//     if(student.department.name == element.name)
//   return student.department
//   }
// })
// const  cleared = this.clearanceData.filter(student => {
//   if(student.department != null){
//     if(student.department.name == element.name & student.status > 5)
//   return student.department
//   }
// })

// // const  notCleared = this.clearanceData.filter(student => {
// //   if(student.department != null){
// //     if(student.department.name == element.name & student.status <= 5)
// //   return student.department
// //   }
// // })

// const  accepted = this.clearanceData.filter(student => {
//   if(student.department != null){
//     if(student.department.name == element.name & student.status >= 3 )
//   return student.department
//   }
// })

//  departmentCounts.push({
//   department:element.name,
//   numberAdmited:maped.length,
//   numberAccepted : accepted.length,
//   numberCleared : cleared.length,
// //   numberNotCleared : notCleared.length

// })
// })
// console.log(departmentCounts);
// this.desserts = departmentCounts;
// this.dataLoaded = true
            }
        }
}
}
</script>

<style>

</style>
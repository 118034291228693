<template>
<div class="home">
        <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">Find Admited Students</span>
        </div>
    </div>

    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
        <v-row style="margin:0 !important; padding:0 !important">
        <v-col cols="12" style="">
        <v-card elevation="3">
            <v-card-title>
                <div class="cardTableHeader">
Addmited Students Search Filter
</div>
            </v-card-title>
            <v-divider style="margin-top:0"></v-divider>
        <div style="background: #fff;" class=" mb-10 pt-10 px-10 filter">
            <div class="row">
                <div class="col-md-12 ">
                    <div class="row">
                    <!-- <div class="col-md-3" style="margin:auto">
                        <v-autocomplete clearable item-text="name" item-value="slug" v-model="program" :items="programs"  label="Select Programe" outlined dense> </v-autocomplete>

                    </div> -->
                    <div class="col-md-3" >
                        <v-autocomplete clearable item-text="name" item-value="_id" v-model="year" :items="admissionYear" label="Admission window" outlined dense> </v-autocomplete>

                    </div>

                    <div class="col-lg-3" >
<v-text-field type="text" v-model="regNumber" class="" id="regno" placeholder="Entry Number" clearable label="Entry Number" outlined dense ></v-text-field>                    </div>

<div class="col-md-3">
                        <v-autocomplete clearable item-text="name" item-value="name" v-model="entry_mode" :items="entryMode" label="Select Entry Mode" outlined dense> </v-autocomplete>
</div>
<div class="col-md-3">
                        <v-autocomplete clearable item-text="name" item-value="_id" v-model="faculty" :items="faculties" label="Select Faculty" outlined dense @change="getDept()"> </v-autocomplete>
                    </div>
                    </div>
                   

                </div>

                <div class="col-md-12 " style="margin-top:-30px">
                    <div class="row" >
                        

                    <div class="col-md-3" >
                        <v-autocomplete clearable item-text="name" item-value="_id" v-model="department" :items="departments" label="Select Department" outlined dense> </v-autocomplete>
                    </div>

                    <div class="col-md-3" >
                        <v-autocomplete clearable item-text="name" item-value="value" v-model="status" :items="statuses" label="Status" outlined dense> </v-autocomplete>
                    </div>
                        </div>
                        </div>

                        <v-card-actions class="col-md-2">
                        <v-btn color="#c0e7fe" @click="filterAdmittedStudents"  v-if="!activity" class="col-md-12 ma-auto" >Search</v-btn>
                        <v-btn color="#c0e7fe"    class="col-md-12 ma-auto" disabled v-else ><v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp;Searching</v-btn>
                        
                        </v-card-actions>


                <!-- <div class="col-lg-12 pullup">
                    <div class="col-lg-4" style="margin:auto">
                        <v-btn @click="filterAdmittedStudents" class="_btn" block>Search</v-btn>
                    </div>
                </div> -->
            </div>
        </div>
    </v-card>
<div class="row" v-if="foundData">
        <div class="col-lg-12">
            <div class="table_clear">
                <v-card>
                    <v-card-title>
                        <div class="cardTableHeader">List of Admitted Students</div>
                        <v-spacer></v-spacer>
<!-- 
                        <button @click="csvExport(csvData)" class="download_b" type="submit"> <span style="margin-right: 4px"></span> Download</button> -->
                        <!-- <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Result" single-line hide-details></v-text-field> -->

                    </v-card-title>
                    <v-divider style="margin-top:0"></v-divider>                                

                    <v-data-table :headers="headers" :items="datas" :search="search" sort-by="" class="elevation-1">
                        <template v-slot:[`item.sn`]="{ item }">
                     
                            <div class="table-txt">{{datas.map(function(x) {return x.reg_num; }).indexOf(item.reg_num)+1}}</div>
                       
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                            <div v-if="item.status == '2'">Approved</div>
                            <div v-if="item.status == '1'">Awaiting Approval</div>

                    </template>
                    </v-data-table>
                </v-card>
            </div>
        </div>
    </div>
    </v-col>
    </v-row>
    </div>
    
</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
    data() {
        return {
            program: "",
            search:'',
            foundData:false,
            programs: [],
            activity:false,
            faculty: "",
            faculties: [],
            department: "",
            departments: [],
            year: "",
            statuses:[{"name":"Approved","value":"2"},{"name":"Awaiting Approval","value":"1"}],
            admissionYear: ["2021-2022","2022-2023","2023-2024"],
            regNumber: "",
            entry_mode: "",
            entryMode: [],
            menu1: false,
            date: "",
            date1: "",
            facultyArr: [],
            departmentArr: [],
            menu2: false,
            headers: [
                 {
                text: 'SN',
                align: 'start',
                sortable: false,
                value: 'sn',

            },{
                    text: "Entry Number",
                    value: "reg_num",
                },
                
                {
                    text: "Full Name",
                    value: "name",
                },
                {
                    text: "Department",
                    value: "dep",
                },
                {
                    text: "Faculty",
                    value: "fac",
                },
                {
                    text: "Aggregate",
                    value: "agg",
                },
                {
                    text: "Post utme",
                    value: "putme",
                },
                {
                    text: "O'level",
                    value: "olev",
                },
                {
                    text: "Entry Point",
                    value: "entry",
                },
                {
                    text: "Status",
                    value: "status",
                },
                {
                    text: "Uploaded By",
                    value: "uploadby",
                },
            ],
            datas: [],
        };
    },
    watch: {
        faculty(val) {
            this.departments = [];
             this.departments = this.departmentArr.filter((el) => el.faculty._id == val);
            // departments1.forEach((el) => {
            //     this.departments.push(el);
            // });
            console.log(this.departments);
        },
    },

    beforeMount() {
        this.recieveDept(this.$router.currentRoute.params.response)
        this.recieveFac(this.$router.currentRoute.params.response1)
        this.recieveEmode(this.$router.currentRoute.params.response3)

        // this.fetchFaculty();
        // this.fetchEntryFrom();
        // this.fetchDepartments();
        // this.fetchEntryFrom();
    },
    mounted(){
        this.program = this.$router.currentRoute.params.activeSetting.data.data.program._id;
    },
    methods: {
        
        recieveDept(x) {
            console.log(x.message);
            this.departmentArr = x.message
        },
        // Recieve thee fact payload from the router call before route
        recieveFac(x) {
            console.log(x.message);
            this.faculties = x.message;

        },
        recieveEmode(x) {
            console.log(x.message);
            this.entryMode = x.message;

        },
       
        getDept(){
// this.departments = this.departmentArr.map(x=>{
//     return x.faculty == this.faculty
// })
        },
     
        async filterAdmittedStudents() {
            this.activity = true
            this.foundData = false
            try {

                if (!this.year){
                    this.activity = false
                    return Swal.fire({
                        icon: "info",
                        text: "Please select admission window",
                    });
                }
                // {
                //   entry_mode: this.entryPoint, year: this.year, faculty:
                //   this.faculty, department: this.department, program:
                //   this.program, regNumber: this.regNumber, date: this.date, date1: this.date1
                // }
                const data = {};

                if (this.entry_mode) {
                    data["entryPoint"] = this.entry_mode;
                }

                if (this.faculty) {
                    data["faculty"] = this.faculty;
                }

                if (this.department) {
                    data["department"] = this.department;
                }
                if (this.entry_mode) {
                    data["entryPoint"] = this.entry_mode;
                }
                if (this.regNumber) {
                    data["regNumber"] = this.regNumber;
                }
                if (this.status) {
                    data["status"] = this.status;
                }
                 const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT + '/filter-admitted-students'
                const res = await axios.post(
                    url, {
                        staffId:`${this.$logedIndataId}`,
                        year: this.year,
                        data
                    }
                );
                console.log(res);
                if (res.data.code == "00") {
                    
                    if (res.data.message.length == 0) {
                        Swal.fire({
                            icon: "info",
                            text: "Admited Student(s) Not Found"
                        });
                    }else{
                        this.foundData = true

                    }
                    this.datas = res.data.message;
                    this.activity = false
                    
                }
                
            } catch (error) {
                this.activity = false
                this.foundData = false

                console.log(error);
            }
        },
        // async fetchEntryFrom() {
        //     try {
        //         const res = await axios.get("http://127.0.0.1:5000/fetch-entry-from");
        //         console.log("Entry0", res);
        //         if (res.data.code == "00") {
        //             const arr = [];
        //             res.data.data.forEach((el) => {
        //                 arr.push(el.name.toUpperCase());
        //             });
        //             this.entryMode.push(...arr);
        //         }
        //     } catch (error) {
        //         console.log(error);
        //     }
        // },
    },
};
</script>

<style scoped>

</style>

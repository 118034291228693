<template>
  <div class="home">
        <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">Upload Admission</span>
        </div>
    </div>

    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
      <v-alert style="color:#ff735e"> <strong>UNDERGRADUATE REGULAR 2023/2024 ADMISSION WINDOW</strong> <v-btn x-small style="margin-left:20px">Switch Admission Window</v-btn></v-alert> 

      <v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
      <div class=" mb-8">
        <v-card  v-if="showForm1" style="margin: 0 auto" class="pa-12" width="700">
          <div>
            <v-autocomplete dense :items="degrees" v-model="degree" outlined label="Select Degree"></v-autocomplete>
            <v-btn color="#c0e7fe" block @click="showForm2" class="ma-auto pa-3">Next</v-btn>
            <!-- <v-btn color="primary" class="pa-3" block @click="shownext=true">Next</v-btn> -->
          </div>

          </v-card>

          <v-card v-if="!isUpload && !showForm1" elevation="0" style="margin: 0 auto" class="pa-12" width="700">

            <v-form  v-if="!isUpload" class="mt-12">
  
  <v-row>
    <v-col class="col-sm-0 col-md-1 col-lg-2"></v-col>
    <v-col class="col-sm-12 col-md-10 col-lg-8">
      <v-row>

        <!-- <v-col class="col-sm-12 col-md-6 col-lg-6 py-0">
          <label for=""><strong>Select Entry Point</strong></label>
          <v-select dense outlined v-model="from" required :items="entryPoint" label="Select Entry Point" class="mr-3"></v-select>
        </v-col> -->
        <v-autocomplete dense :items="entryPoint" v-model="from" outlined label="Select Entry Point"></v-autocomplete>

        <!-- <v-col class="col-sm-12 col-md-6 col-lg-6 py-0">
          <label for=""><strong>Select Faculty</strong></label>
          <v-select dense outlined required v-model="faculty" :items="faculties" label="Select Faculty" class="mr-3"></v-select>
        </v-col> -->
        <v-autocomplete dense :items="faculties" v-model="faculty" outlined label="Select Faculty"></v-autocomplete>

        <!-- <v-col class="col-sm-12 col-md-6 col-lg-6 py-0">
          <label for=""><strong>Select Department</strong></label>
          <v-select dense outlined v-model="department" required :items="departments" label="Select Department" class="mr-3"></v-select>
        </v-col> -->
        <v-autocomplete dense :items="departments" v-model="department" outlined label="Select Department"></v-autocomplete>

        <!-- <v-col class="col-sm-12 col-md-6 col-lg-6 py-0">
          <label for=""><strong>Select Add Mode</strong></label>
          <v-select dense outlined required v-model="addmode" :items="addmodes" label="Select Add modes" class="mr-3"></v-select>
        </v-col> -->
          <v-file-input class="col-md-12" dense required id="fileInput" outlined MouseEvent truncate-length="23" @change="uploadcsv" label="Admission Upload File" :disabled="disableFile"></v-file-input>

        <!-- @click="checkFeild" -->
      </v-row>
    </v-col>
    <v-col class="col-sm-0 col-md-1 col-lg-2"></v-col>
  </v-row>
  <v-row>
    <v-col class="col-sm-0 col-md-3 col-lg-4"></v-col>
    <v-col class="col-sm-12 col-md-6 col-lg-4">
      <div style="text-align: center">
        <v-btn class="mr-3 text-right text-white " style="background: #006794; height: 50px" @click="next" block>
          Next
        </v-btn>
      </div>
    </v-col>
    <v-col class="col-sm-0 col-md-3 col-lg-4"></v-col>
  </v-row>
</v-form>


            </v-card>
          </div>


          </v-col>
          </v-row>




    <v-card elevation="0" class="" v-if="isUpload">
      <v-row>       
          <v-card-title style="font-weight:100;">
            Uploading {{ addmode }} from {{ from }} for {{ session }} Admission into {{` ${department.name ? department.name : ''}`}} department 
          </v-card-title>
        
              <v-col  v-if="badData.length>0">
                  <v-toolbar class="mb-2" flat>
                    <v-toolbar-title>{{badData.length}} Bad Data</v-toolbar-title>
                  </v-toolbar>
                  <v-data-table :headers="headers" :items="badData"> </v-data-table>
              </v-col>
              <v-col  v-if="approveList.length>0">
                  <v-toolbar class="mb-2" flat>
                    <v-toolbar-title>{{approveList.length}} Good Data</v-toolbar-title>
                  </v-toolbar>
                  <v-data-table :headers="headers" :items="approveList"> </v-data-table>
  
                <v-row class="">
                  <v-col cols="col-sm-0 col-md-3 col-lg-2"></v-col>
                  <v-col cols="col-sm-12 col-md-3 col-lg-4">
                    <v-btn style="background: #006794; color: #fff; height: 50px" block color="blue-grey" @click="back">
                      Back
                    </v-btn>
                  </v-col>
                  <v-col cols="col-sm-12 col-md-3 col-lg-4">
                    <v-btn :disabled="disableBtn" style="background: #D6ECF5; color:#006794; height: 50px" block @click="dialog = true">
                      Proceed
                    </v-btn>
                  </v-col>
  
                  <v-col cols="col-sm-12 col-md-3 col-lg-2"></v-col>
                </v-row>
              </v-col>
            </v-row>
  
       







      <v-dialog v-model="showbouncedData" max-width="1100" persistent>
      <v-card>
        <v-card-title style="color:#666; font-size:18px">
          Unprocessed data
          <v-spacer></v-spacer>
          <v-icon @click="showbouncedData=false">mdi-close-circle</v-icon>
  
        </v-card-title>
        <v-divider style="margin-top:0px"></v-divider>
  
        <div class="pa-6">
          <div class="mb-4">
            <v-data-table :headers="headers" :items="unprocessedData">
  
            </v-data-table>
          </div>
  
        </div>
        <v-card-actions>
  
          <v-btn color="#c0e7fe" @click="showbouncedData=false" class="col-md-5">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-dialog v-model="dialog" width="450">
        <v-card class="pa-3" elevation="0">
          <v-card-title style="" class="pa-3">
            <div>
              Enter Otp key
            </div>
          </v-card-title>
          <v-form class="px-5">
            <v-text-field v-model="otp" outlined label="Enter Code"></v-text-field>
  
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  block style="height: 50px; background: #006794; color: #fff" @click="checkotp" v-if="!processingload">
                Confirm
              </v-btn>
              <v-btn  v-else block style="height: 50px; background: #006794; color: #fff" disabled>

              <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular>
    Uploading Admission
    </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <!-- <v-dialog v-model="showUpload" width="400">
        <v-card class="pa-12" elevation="0">
         
          <v-btn style="background: #D6ECF5; color: #006794; font-wieght: bold; height: 50px" class="" block @click="genOtp">
            Generate Otp
          </v-btn>
          <v-btn style="background: #006794; font-wieght: bold; height: 50px" class=" text-right text-white mt-6 " block @click="closeApprove">
            Approve
          </v-btn>
        </v-card>
      </v-dialog> -->
  
    </v-card>
  </div>
  </div>
  </template>
  
  <script>
  import readXlsxFile from "read-excel-file";
  import Swal from "sweetalert2";
  import axios from "axios";
  import { degree, allSession, allDepartments, allFaculties,menusAsigned,fetchactiveSets } from "@Appmart/utility";

  // import {
  //   mapGetters,
  //   mapActions
  // } from 'vuex';
  export default {
    data() {
      return {
        showbouncedData: false,
        showForm1: true,
        disableFile: true,
        disableBtn: false,
        otp: null,
        isUpload: false,
        showUpload: false,
        addmode: '',
        uplist:[],
        processingload:false,
        addmodes: [{
            text: 'Merit',
            value: 'MERIT'
          },
          {
            text: 'Catchment Area',
            value: 'CAT'
          },
          {
            text: 'Educational Less Priviledge',
            value: 'ELDS'
          },
          {
            text: 'Biological Child',
            value: 'BioChild'
          },
          {
            text: 'Suplementry 1',
            value: 'S1'
          },
          {
            text: 'Suplementry 2',
            value: 'S2'
          },
          {
            text: 'Suplementry 3',
            value: 'S3'
          },
          {
            text: 'Suplementry 4',
            value: 'sup 4'
          },
          {
            text: 'Suplementry 5',
            value: 'S5'
          },
          {
            text: 'Suplementry 6',
            value: 'S6'
          },
          {
            text: 'Suplementry 7',
            value: 'S7'
          },
          {
            text: 'Suplementry 8',
            value: 'S8'
          }
        ],
        dialog: false,
        departmentsHolder: [],
        facultyHolder: [],
        degrees: [],
        degree: '',
        department: "",
        approveList: [],
        faculty: "",
        from: "",
        session: '2023',
        program:this.$activeSetting.data.program._id,
        sessions: [],
        programs: [],
        entryPoint: ['JAMB','DIRECT ENTRY','PRE SCIENCE','JUPEB'],
        faculties: [],
        badData: [],
        departments: [],
        headers: [{
            text: "Reg Number",
            value: "regnumber",
          },
          // {
          //   text: 'Surname',
          //   value: 'surname'
          // },
          {
            text: "Name",
            value: "name",
          },
          // {
          //   text: 'Other Names',
          //   value: 'otherName'
          // },
          {
            text: "Sex",
            value: "sex",
          },
          {
            text: "LGA",
            value: "lga",
          },
          {
            text: "State",
            value: "state",
          },
          {
            text: "Utme",
            value: "utme",
          },
         
          {
            text: "O'level",
            value: "olevel",
          },
          {
            text: "Post Utme",
            value: "putme",
          },
          {
            text: "Aggregate",
            value: "agg",
          },
          {
            text: "Admission Batch",
            value: "admode",
          },

          {
            text: "Entry Mode",
            value: "entry",
          },
          {
            text: "Faculty",
            value: "faculty",
          },
          {
            text: "Department",
            value: "department",
          },
         
        ],
        datas: [],
        reason: '',
        unprocessedData: []
      };
    },
    computed: {
      // ...mapGetters('programs', [
      //   'getPrograms',
      // ]),
    },
    beforeMount() {
      // this.fetchPrograms();
      // this.fetchallDegrees()
      this.fetchFaculties();
      this.fetchDepartments();
      // this.fetchEntryFrom();
      // this.fetchSessions()
      this.getPrograms();
    },
    watch: {
      faculty(val) {
        this.populateRightDepartments(val);
        if (val && this.department) {
          this.disableFile = false;
        }
      },
  
      department(val) {
        if (val && this.faculty) {
          this.disableFile = false;
        }
      },
    },
    methods: {
      // reset(){
      //   this.stream = "";
      //       this.from = "";
      //       this.addmode = '';
      //       this.department = "";
      //   this.program = '';
      //   this.degree = '';
      //   this.session = '';
      //       this.faculty = "";
      //       this.showForm1 = true;
    
      //   this.otp = '';
      //       this.approveList = [];
      //       // this.$router.go(this.$router.currentRoute);
      //       this.isUpload = false;
      //       this.showUpload = false;
      //       this.dialog = false;
      // },
     
      // ...mapActions('programs', [
      //   'fetchPrograms', // -> this.fetchPrograms()
      // ]),
      checkFeild() {
        if (!this.faculty || !this.department)
          return Swal.fire({
            icon: "info",
            title: "Please select file for upload",
          });
        else {
          this.disableFile = true;
          return this.uploadcsv();
        }
      },
      // closeApprove() {
      //   this.showUpload = false;
      //   this.dialog = true;
      // },
      showForm2(){
        if(!this.program || !this.degree || !this.session){
          return Swal.fire({icon: 'error', text: 'Please make sure to select all fields', title: 'Incomplete'})
        }else{
  
          this.showForm1 = false;
        }
      },
      // back() {
      //   this.isUpload = false;
      //   this.datas = [];
      //   this.approveList = [];
   
      //   this.stream = "";
      //   this.from = "";
      //   this.addmode = '';
      //   this.department = "";
  
      //   this.faculty = "";
  
      //   this.isUpload = false;
      //   this.showUpload = false;
      //   this.dialog = false;
      // },
      // async genOtp() {
      //   try {
      //     const res = await axios.post(
      //       "http://127.0.0.1:5000/gen-otp", {
      //         createdBy: "ac.enendu@unizik.edu.ng",
      //       }
      //     );
      //     if (res.data.code == "00") {
      //       Swal.fire({
      //         icon: "success",
      //         title: "Otp send to phone",
      //         width: 400,
      //       });
      //     }
      //   } catch (error) {
      //     console.log(error);
      //   }
      // },
      next() {
        if (
          !this.department
        ){
          return Swal.fire({
            icon: "info",
            text: "Select Department",
            title: "Admission Upload",
            width: 350,
          });
        }

        if (
          !this.program
        ){
          return Swal.fire({
            icon: "info",
            text: "Select Program",
            title: "Admission Upload",
            width: 350,
          });
        }

        if (
          !this.faculty
        ){
          return Swal.fire({
            icon: "info",
            text: "Select Faculty",
            title: "Admission Upload",
            width: 350,
          });
        }

        if (
          !this.session
        ){
          return Swal.fire({
            icon: "info",
            text: "Active Session not available",
            title: "Admission Upload",
            width: 350,
          });
        }

        if (
          !this.from
        ){
          return Swal.fire({
            icon: "info",
            text: "Select Entry Point",
            title: "Admission Upload",
            width: 350,
          });
        }
        // !this.program ||
        //   !this.faculty ||
        //   !this.session ||
        //   !this.from ||
        //   this.approveList.length == 0 &&
        //   this.badData.length == 0

        this.isUpload = true;
      },
    
      checkotp() {
        if (!this.otp)
          return Swal.fire({
            title: "Admission Upload",
            text: "Please Enter otp code",
            icon: "error",
          });
          this.approveList.map(z=>{
            const d = {
                    ...z,
                  };
          this.uplist.push(d)
        })
        this.upload();
  
        this.otp = null;
      },
      async getPrograms() {
        try {
        const programDegree = await degree()
          .then((activeSet) => {
            return activeSet;
          })
          .catch((e) => {
            console.log(e);
          });

        // if (degree.data.code == "00") {
          programDegree.data.message.forEach(el => {
                this.degrees.push({
                  text: el.name,
                  value: {
                    id: el._id,
                    name: el.name
                  }
                })
              });
        // }
      } catch (error) {
        console.log(error);
      }
       
      },
      populateRightDepartments(val) {
        console.log(val);
  
        const departmentDatas = this.departmentsHolder.data.message.filter(
          (el) => el.faculty._id == val.id
        );
        const departments = departmentDatas.map((el) => {
          return {
            text: el.name,
            value: {
              name: el.name,
              id: el._id
            }
          }
        });
        this.departments = departments;
      },
      // async fetchEntryFrom() {
      //   try {
      //     const res = await axios.get("http://127.0.0.1:5000/fetch-entry-from");
      //     if (res.data.code === "s200") {
      //       for (const el of res.data.message) {
      //         this.entryPoint.push(el.name.toUpperCase());
      //       }
      //     }
      //   } catch (err) {
      //     console.log(err);
      //   }
      // },
      async upload() {        
       this.processingload = true
        try {
          const data = {
            session: this.session,
            program: this.program,
            department: this.department.id,
            faculty: this.faculty.id,
            from: this.from,
            approveList:this.uplist,
            degreeId: this.degree,
            otp: this.otp,
          };
          // console.log(this.otp);
          var url = this.$appUrl+'/upadmissionbatch'

          const res = await axios.post(url,
          data,{withCredentials: true}
          );
          console.log('res ponse', res);
          if (res.data.code == "s200") {
            this.uplist = [];
this.processingload = false
this.dialog = false;

            if(res.data.unprocessed.length !== 0){
              this.unprocessedData = [];
  
  this.unprocessedData = res.data.unprocessed.map(el => {
    this.reason = el.reason;
    return {
      regnumber: el.regNumber,
  name: `${el.firstName} ${el.surname}`,
  sex: el.gender,
  state: el.state,
  agg: el.aggregate, 
  course: this.department.name,
  entry: this.entryPoint,
  putme: this.postUtme,
  
  lga: el.lga,
  admode: this.addmode,
  utme: this.postUtme,
  faculty: this.faculty.name,
  department: this.department.name,
  olevel: el.olevel
    }
  })
  
  this.datas = this.unprocessedData;
  
  this.showbouncedData = true;
            }else{
              this.isUpload = false;
              this.showForm1 = false
            this.showUpload = false;
            this.dialog = false;
            this.approveList = [];
  
             Swal.fire({
              icon: "success",
              text: res.data.message,
              title: "Admission Upload",
              width: 350,
            });
            }
         
            this.stream = "";
            this.from = "";
            this.addmode = '';
            this.department = "";
   
            this.faculty = "";
         
            // this.$router.go(this.$router.currentRoute);
          //   return Swal.fire({
          //   icon: "success",
          //   text: "Admission List Uploaded",
          //   title: "Error",
          //   width: 350,
          // });
          } else{
            this.dialog = false;
            this.processingload = false
            return Swal.fire({
              icon: "error",
              text: res.data.message,
              title: "Oops..",
              width: 350,
              height: 300,
            });
          }
        } catch (error) {
          this.dialog = false;
          this.processingload = false
          console.log(error);
          // return Swal.fire({
          //   icon: "error",
          //   text: "Something went wrong! Try again",
          //   title: "Error",
          //   width: 350,
          // });
        }
      },
      async fetchFaculties() {
        try {

const allFacs = await allFaculties()
.then((faculties) => {
return faculties;
})
.catch((e) => {
console.log(e);
});

  this.faculties = allFacs.data.message.map((el) => {
              return {
                text: el.name,
                value: {
                  name: el.name,
                  id: el._id
                }
              }
            });
}catch(e){
console.log(e);
}

     
        
      
      },
      async fetchDepartments() {
        try {

const alldepartments = await allDepartments()
.then((departments) => {
return departments;
})
.catch((e) => {
console.log(e);
});
        this.departmentsHolder = alldepartments;

}catch(e){
console.log(e);
}

        

      },
      uploadcsv() {
        this.badData = [];
        this.approveList = [];
        const input = document.getElementById("fileInput");
        readXlsxFile(input.files[0]).then((rows) => {
          const key = rows[0];
          // console.log('ROWS',rows);
  
          if (
            // key[0] != ['AdmissionCategoryName'] ||
            // key[1] != ['AdmissionStatus'] ||
            // key[2] != ['CO_NAME'] ||
            // key[3] != ['JAMB']	||
            // key[4] != ['LGA_NAME'] ||
            // key[5] != ['PUTMESCORE'] ||
            // key[6] != ['PostUTME'] ||
            // key[7] != ['RG_AGGREGATE']	||
            // key[8] != ['RG_CANDNAME'] ||
            // key[9] != ['RG_NUM'] ||
            // key[10] != ['RG_SEX'] ||
            // key[11] != ['STATE_NAME'] ||
            // key[12] !=	['Total']
            // RG_NUM	RG_CANDNAME	RG_SEX	STATE_NAME	RG_AGGREGATE	
            // CO_NAME	PUTMESCORE	AdmissionStatus	LGA_NAME	ALL ADD.MODE	
            // DEPT ADMITTED	UTME	O'LEVEL	DE ADD.MODE	COURSE ADMITTED
            key[0] != "RG_NUM" ||
            key[1] != "RG_CANDNAME" ||
            key[2] != "RG_SEX" ||
            key[3] != "LGA_NAME" ||
            key[4] != "STATE_NAME" ||
            key[5] != "UTME" ||
            key[6] != "O'LEVEL"||
            key[7] != "PUTMESCORE" ||
            key[8] != "RG_AGGREGATE" ||
            key[9] != "ADMISSION BATCH"

          ) {
            console.log("ERROR");
            Swal.fire(
              "Wrong Admission Upload Template Format",
              '<a href="https://appcentral.unizik.edu.ng/templates/formh1template.xlsx" style="font-size:12px">Click here to download result template</a>',
              "error"
            );
          } else {
            rows.shift(); //Remove the header row

            console.log({rows});
            let newformat = [];
            let badFormat = [];
            
             rows.forEach(row=>{
  
              // // this.approveList.push(row[0]);
              // this.approveList.push({
              //   reg: `${row[0]}`,
              //   name: ` ${row[1]}`,
              // });
              if (row[13] == null && row[14] == null) {
                delete row[13];
                delete row[14]
              }
  
              //1AdmissionCategoryName 2	AdmissionStatus  3	CO_NAME	4 JAMB 	5 LGA_NAME
              //	6 PUTMESCORE	
              //7 PostUTME 8 RG_AGGREGATE	9 RG_CANDNAME	10 RG_NUM 11 RG_SEX	12 STATE_NAME	13Total
  
              if (this.from == 'JAMB' || this.from == 'PRE SCIENCE') {
                if (row.includes(null)) {
                  row["regnumber"] = row[0];
                  row["name"] = row[1];
                  row["sex"] = row[2];
                  delete row[2];
                  row["lga"] = row[3];
                  delete row[3];
                  row['state'] = row[4];
                  delete row[4];
                  row["utme"] = row[5];
                  delete row[5];
                  row["olevel"] = row[6];
                  delete row[6];
                  row["putme"] = row[7];
                  delete row[7];
                  row["agg"] = row[8];
                  delete row[8];
                  row["admode"] = row[9];
                  delete row[9];
                  row['entry'] = this.from
                  row["department"] = this.department.name;
                  row["faculty"] = this.faculty.name;
                  // row['mode'] = this.addmode
                  // row["addmode"] = row[13];
                  // delete row[13];
                  // row["admitmode"] = row[14];
                  // delete row[14];
                  badFormat.push(row);
                  // console.log('BAD',badFormat);
                } else {
  
                  row["regnumber"] = row[0];
                  delete row[0];
                  row["name"] = row[1];
                  delete row[1];
                  row["sex"] = row[2];
                  delete row[2];
                  row["lga"] = row[3];
                  delete row[3];
                  row['state'] = row[4];
                  delete row[4];
                  row["utme"] = row[5];
                  delete row[5];
                  row["olevel"] = row[6];
                  delete row[6];
                  row["putme"] = row[7];
                  delete row[7];
                  row["agg"] = row[8];
                  delete row[8];
                  row["admode"] = row[9];
                  delete row[9];
                  row['entry'] = this.from
                  row["department"] = this.department.name;
                  row["faculty"] = this.faculty.name;
                  // row["addmode"] = row[13];
                  // delete row[13];
                  // row["admitmode"] = row[14];
                  // delete row[14];
                  newformat.push(row);
                }
                // newformat.forEach((el) => {
                //   const d = {
                //     ...el,
                //   };
                //   this.approveList.push(d);
                // });
                // this.datas = this.approveList;
  
                badFormat.forEach(el => {
                  const d = {
                    ...el,
                  };
                  this.badData.push(d);
                })


              } else {
                row["regnumber"] = row[0];
                  delete row[0];
                  row["name"] = row[1];
                  delete row[1];
                  row["sex"] = row[2];
                  delete row[2];
                  row["lga"] = row[3];
                  delete row[3];
                  row['state'] = row[4];
                  delete row[4];
                  row["utme"] = row[5];
                  delete row[5];
                  row["olevel"] = row[6];
                  delete row[6];
                  row["putme"] = row[7];
                  delete row[7];
                  row["agg"] = row[8];
                  delete row[8];
                  row["admode"] = row[9];
                  delete row[9];
                  row['entry'] = this.from
                  row["department"] = this.department.name;
                  row["faculty"] = this.faculty.name;
                // row["addmode"] = row[13];
                // delete row[13];
                // row["admitmode"] = row[14];
                // delete row[14];
                newformat.push(row);
              }
  
              // this.approveList =
              //   // newformat.forEach((el) => {
              //   //     const d = {
              //   //         ...el,
              //   //     };
              //   //     this.approveList.push(d);
              //   // });
  
              //   // console.log("Newformat", newformat);
  
              //   this.datas = this.approveList;
  
            })
            this.approveList = newformat
  //           const key = 'regnumber';
  //         this.datas = [...new Map(this.approveList.map(item =>
  // [item[key], item])).values()];
          }
        })
      },
    },
  };
  </script>
  
  <style>
  * {
    box-sizing: border-box;
  }
  
  tbody tr {
    margin: 4px 0 !important;
    box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.336);
    -webkit-box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.301);
    -moz-box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.288);
  }
  
  .v-data-table-header {
    background: #d6ecf5;
    font-weight: bolder;
    color: #006794;
  }
  </style>
  
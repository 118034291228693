<template>
  <div class="home">
    <div class="row">
      <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
        <span style="color:#777; font-size:18px;padding:5px;">Settings</span>/<span style="padding:5px; color:#555">Add Students</span>
      </div>
    </div>
  
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
  
      <v-card min-height="400" class="row">
        <v-col class="pa-0 col-lg-6 col-md-12" style="border-right:1px solid #000">
          <div class="header">
            <h4>Bio Info</h4>
          </div>
  
          <div class="pa-9 pb-0" style="padding-bottom: 0 !important;">
  
      
            <v-text-field outlined dense v-model="firstname" label="Enter First Name"></v-text-field>
  
            
            <v-text-field outlined dense v-model="lastname" label="Enter Last Name"></v-text-field>
  
           
            <v-text-field outlined dense v-model="surname" label="Enter Surname"></v-text-field>
  
   
            <v-text-field outlined dense v-model="email" label="Enter Email"></v-text-field>
  
          
            <v-text-field outlined dense v-model="phone" label="Enter Phone Number"></v-text-field>
  
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dateofbirth" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dateofbirth" label="DOB" prepend-icon="mdi-calendar" readonly dense outlined v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateofbirth" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(dateofbirth)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
  
  
            <v-autocomplete dense :items="states" v-model="state" outlined label="Select State"></v-autocomplete>
  
  
            <v-autocomplete :disabled="lgas.length == 0" dense :items="lgas" v-model="lga" outlined label="Select lga"></v-autocomplete>
  
  
            <v-autocomplete dense :items="genders" v-model="gender" outlined label="Select Gender"></v-autocomplete>
  
            <v-textarea outlined v-model="reason" label="Add a reason for uploading this student" cols="20" rows="5"></v-textarea>
          </div>
        </v-col>
  
        <v-col class="pa-0 col-lg-6 col-md-12">
          <div class="header">
            <h4>Academic Info</h4>
          </div>
  
          <div class="pa-9 pb-0" style="padding-bottom: 0 !important;">
  
  
            <v-text-field outlined dense v-model="regNumber" label="Enter Reg Number"></v-text-field>
  
            <v-text-field outlined dense v-model="admissionNumber" label="Enter Admission Number"></v-text-field>
  
  
            <v-autocomplete :disabled="true" dense :items="programs" v-model="program" outlined label="Select Program"></v-autocomplete>
  
            <v-autocomplete dense :items="sessions" v-model="session" outlined label="Year of Admission"></v-autocomplete>
  
  
            <v-autocomplete dense :items="degrees" v-model="degree" outlined label="Select Degree"></v-autocomplete>
  
            <v-autocomplete dense :items="entryModes" v-model="entrymode" outlined label="Select Entry Point"></v-autocomplete>
  
  
            <v-autocomplete dense :items="faculties" v-model="faculty" outlined label="Select Faculty"></v-autocomplete>
  
  
            <v-autocomplete :loading="loading1" :disabled="!faculty && !degree" dense :items="departments" v-model="department" outlined label="Select Department"></v-autocomplete>
  
  
            <v-autocomplete dense :items="levels" v-model="level" outlined label="Select Level"></v-autocomplete>
  
            <v-autocomplete dense :items="[true, false]" v-model="spillover" outlined label="Spill over"></v-autocomplete>
  
  
            <!-- <v-file-input id="file" @change="uploadFile" show-size  outlined dense label="File input"></v-file-input> -->
          </div>
        </v-col>
        <v-col>
          <div class="mt-12 text-center">
            <v-btn @click="submit" class="btn-search" color="#006794">Submit</v-btn>
          </div>
        </v-col>
      </v-card>
  
    </div>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import stateLGA from './stateLga';
  import { degree, allSession, allDepartments, allFaculties,menusAsigned,fetchactiveSets } from "@Appmart/utility";

  export default {
    data() {
      return {
        spillover: null,
        reason: '',
        menu: false,
        loading1: false,
        loading: false,
        modal: false,
        statelga: [],
        states: [],
        lgas: [],
        regNumber: '',
        admissionNumber: '',
        dob: '',
        dateofbirth: '',
        session: '',
        sessions: [],
        entrymode: '',
        email: '',
        entryModes: ['JAMB','DIRECT ENTRY','PRE SCIENCE'],
        mob: '',
        yob: '',
        firstname: '',
        lastname: '',
        surname: '',
        program: '61ef4514a88631b45cd21f31',
        department: '',
        faculty: '',
        faculties: [],
        departments: [],
     
        state: '',
        degree: '',
        degrees: [],
        lga: '',
        departmentsHolder: [],
  
        programs: [{
          text: 'Undergraduate Regular',
          value: '61ef4514a88631b45cd21f31'
        }],
        gender: '',
        genders: [{
          text: 'Male',
          value: 'M'
        }, {
          text: 'Female',
          value: 'F'
        }],
        phone: '',
        file:null,
        level: '',
        levels: [1,2,3,4,5,6,7],
      }
      
    },
    watch: {
      state(val) {
        if(val){
          const state = this.statelga.filter(el => el.state == val);
        this.lgas = [];
        this.lgas.push(...state[0].lgas);
        }
      },
      faculty(val) {
        if(val){
  
          this.populateRightDepartments(val);
        }
      },
      department(val) {
        if(val){
          this.loading1 = true;
        this.fetchDepartnetNumberOfYears(val)
        }
      },
  
    },
    beforeMount() {
      
      this.statelga = stateLGA;
      this.states = this.statelga.map(el => el.state);
      this.fetchDepartments();
      this.fetchFaculties();
      this.fetchDegree();
      // this.fetchSessions();
      this.genSession();
      // this.fetchEntryFrom();
    },
    methods: {
      genSession(){
        const year = new Date().getFullYear();
        for (let i = 2000; i <= year; i++) {
            this.sessions.push(i);
          
        }
      },
      uploadFile() {
              this.file = document.getElementById('file').files[0];
          },
      reset() {
        this.surname = '';
        this.lastname = '';
        this.firstname = '';
        this.department = '';
        this.regNumber = '';
        this.faculty = '';
        this.session = '';
        this.lga = '';
        this.email = '';
        this.entrymode = '';
        this.dob = '';
        this.admissionNumber = '';
        this.mob = '';
        this.yob = '';
        this.file = null;
        this.phone = '';
        this.level = '';
        this.spillover = null;
        this.reason= '';
        document.getElementById('file').value = "";
        this.degree = '';
        this.gender = '';
        this.dateofbirth = '';
        this.state = '';
        this.semester = '';
        this.gender = '';
        this.loading = false;
        this.loading1 = false;
      },
      validateForm() {
        if (!this.surname || !this.firstname || !this.lastname || !this.program || !this.department || !this.faculty || !this.session || !this.lga || !this.entrymode || !this.gender || !this.regNumber || !this.email || !this.admissionNumber || !this.level || !this.phone || !this.dateofbirth || !this.degree || !this.state || this.spillover == null) {
  
          return false;
        } else {
          return true;
        }
  
      },
      async submit() {
        const dataofB = this.dateofbirth.split('-');
        try {
          if (this.validateForm()) {
            const payload = {
              department: this.department,
              faculty: this.faculty,
              program: this.program,
              firstname: this.firstname,
              othernames: this.lastname,
              surname: this.surname,
              gender: this.gender,
              regNumber: this.regNumber,
              session: this.session,
              DOB: dataofB[2],
              degree: this.degree,
              MOB: dataofB[1],
              reason: this.reason,
              spillover: this.spillover,
              YOB: dataofB[0],
              state: this.state,
              uploadedBy: 'kalchuka@gmail.com',
              admissionNumber: this.admissionNumber,
              lGA: this.lga,
              email: this.email,
  
              entryMode: this.entrymode,
              phone: this.phone,
              level: this.level
            };
  
            // const formData = new FormData();
            //       formData.append('payload', JSON.stringify(payload));
  
            //       if(this.file){
  
            //         formData.append('supportingDocument', this.file)
            //       }
  
                
  
            const res = await axios.post(this.$appUrl + '/add-student', payload);
  
            console.log('RESPONSE', res)
  
            if (res.data.code == 's200') {
              this.reset();
              Swal.fire({
                icon: 'success',
                text: 'Student successfully added',
                title: 'Successful'
              });
            } else {
              Swal.fire({
                icon: 'error',
                text: res.data.message,
                title: 'Error'
              })
            }
          } else {
            /*
            !this.surname || !this.firstname || !this.lastname || !this.program || !this.department || !this.faculty || !this.session || !this.lga || !this.entrymode || !this.gender || !this.regNumber || !this.email || !this.admissionNumber  || !this.level || !this.phone || !this.dateofbirth || !this.degree || !this.state)
            */
  
            
            Swal.fire({
              icon: 'error',
              text: `To proceed make sure to fill out ${!this.surname ? 'surname, ' : ''} ${!this.firstname ? 'first name, ' : ''} ${!this.lastname ? 'last name, ' : ''} ${!this.program ? 'program, ' : ''} ${!this.department ? 'department, ' : ''} ${!this.faculty ? 'faculty, ' : ''} ${!this.session ? 'session, ' : ''} ${!this.lga ? 'lga, ' : ''} ${!this.entrymode ? 'entrymode, ' : ''} ${!this.gender ? 'gender, ' : ''} ${!this.regNumber ? 'reg number, '  : ''} ${!this.email ? 'email, ' : ""} ${!this.admissionNumber ? 'admission number, ' : ''} ${!this.reason ? 'reason, ' : ''} ${!this.level ? 'level, ' : ''} ${!this.phone ? 'Phone'  : ''} ${this.spillover == null ? 'spillover' : ''} ${!this.dateofbirth ? 'Date of birth, ' : ''} ${!this.degree ? 'degree' : ''} `,
              title: 'Oops..'
            });
          }
  
        } catch (error) {
          console.log(error);
        }
      },
      async fetchSessions() {
        try {
          const res = await axios.get(process.env.VUE_APP_BaseUrl + '/fetch-session');
          if (res.data.code === 's200') {
            res.data.message.forEach(session => {
              this.sessions.push({
                text: session.academicYear,
                value: session.academicYear.split('/').join('_')
              })
            });
  
          }
        } catch (error) {
          console.log(error);
        }
      },
      async fetchDepartnetNumberOfYears(departmentId) {
        try {
          const res = await axios.post(process.env.VUE_APP_BaseUrl + '/fetch-no-year', {
            departmentId: departmentId,
            degreeId: this.degree,
            programId: '61ef4514a88631b45cd21f31'
          });
          console.log(res);
  
          if (res.data.code == 's200') {
            for (let i = 0; i < res.data.message; i++) {
              this.levels.push({
                text: `${i+1}00`,
                value: i + 1
              })
            }
          } else {
            Swal.fire({
              icon: 'error',
              text: res.data.message,
              title: 'Oops...'
            })
          }
  
          this.loading1 = false;
  
        } catch (error) {
          console.log(error);
        }
      },
      async fetchEntryFrom() {
        try {
          const res = await axios.get(process.env.VUE_APP_BaseUrl + "/fetch-entry-from");
          if (res.data.code === "s200") {
            for (const el of res.data.message) {
              this.entryModes.push(el.name.toUpperCase());
            }
          }
        } catch (err) {
          console.log(err);
        }
      },
      populateRightDepartments(val) {
        console.log({val});
        if (val === '') return;
        const departmentDatas = this.departmentsHolder.filter(
          (el) => el.faculty._id === val.id
        );
        console.log({departmentDatas});
        const departmental = departmentDatas.map((el) => {
          return {
            text: el.name,
            value: el._id
          }
        });
        this.departments = departmental;
      },
  
      async fetchFaculties() {
        try {

const allFacs = await allFaculties()
.then((faculties) => {
return faculties;
})
.catch((e) => {
console.log(e);
});

  this.faculties = allFacs.data.message.map((el) => {
              return {
                text: el.name,
                value: {
                  name: el.name,
                  id: el._id
                }
              }
            });
}catch(e){
console.log(e);
}
      },
  
      async fetchDepartments() {
        try {

const alldepartments = await allDepartments()
.then((departments) => {
return departments;
})
.catch((e) => {
console.log(e);
});
        this.departmentsHolder = alldepartments.data.message;

}catch(e){
console.log(e);
}
      },
  
      async fetchDegree() {
        try {
        const programDegree = await degree()
          .then((activeSet) => {
            return activeSet;
          })
          .catch((e) => {
            console.log(e);
          });

        // if (degree.data.code == "00") {
          programDegree.data.message.forEach(el => {
                this.degrees.push({
                  text: el.name,
                  value: {
                    id: el._id,
                    name: el.name
                  }
                })
              });
        // }
      } catch (error) {
        console.log(error);
      }
       
  
      },
    }
  }
  </script>
  
  <style scoped>
  .header {
    background: #cccccc7c !important;
    padding: .9rem 1rem;
  
  }
  
  .btn-search {
    color: #fff !important;
    padding: 1.1rem !important;
    font-size: 10px !important;
    width: 400px !important;
  
  }
  </style>
  
import { render, staticRenderFns } from "./Admissiondashboard.vue?vue&type=template&id=88e392d8&scoped=true&"
import script from "./Admissiondashboard.vue?vue&type=script&lang=js&"
export * from "./Admissiondashboard.vue?vue&type=script&lang=js&"
import style0 from "./Admissiondashboard.vue?vue&type=style&index=0&id=88e392d8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88e392d8",
  null
  
)

export default component.exports
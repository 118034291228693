<template >
<div class="home">
        <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">Admission Dashboard</span>
        </div>
    </div>

    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">

        <v-alert style="color:#ff735e"> <strong>UNDERGRADUATE REGULAR 2023/2024 ADMISSION WINDOW</strong> <v-btn x-small style="margin-left:20px" @click="switchWindow()">Switch Admission Window</v-btn></v-alert> 

    <div style="margin:0 !important; padding:0 !important">
    <div cols="12" style="">
        <div class="col-lg-12">
        <router-view name="cardAnalytics"  style="height:258px"></router-view>
        </div>
    <div class="row" style="margin:0; padding:0; margin-top:20px">
        <div class="col-lg-8">
            <router-view></router-view>
        </div>
        <div class="col-lg-4" >
            <div class="chart_class" style="min-height:505.68px">
                <div class="pt-4 pl-4">
                    <h4> 2020/2021 Addmited Demographic Analysis</h4>
                </div>
                <v-divider></v-divider>
                <div style="padding-top:50%"
        class="fill-height"
        align-content="center"
        justify="center" v-if="!dataLoaded"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
        Sorting Data for analysis
        </v-col>
        <v-col cols="12">
          <v-progress-linear
            color="deep-purple accent-1"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </div>
                <div v-else>
                <div class="chart_one">
                    <h6 class="pl-4">Gender</h6>
                    <div id="chart">
                        <apexchart type="donut" width="270" :options="chartOptionsone" :series="seriesone"></apexchart>
                    </div>
                    <h6 class="pl-4">Special needs students</h6>
                    <div id="chart">
                        <apexchart type="donut" width="270" :options="chartOptionsTwo" :series="seriesTwo"></apexchart>
                    </div>
                    <h6 class="pl-4">Nationality</h6>
                    <div id="chart">
                        <apexchart type="donut" width="270" :options="chartOptionsThree" :series="seriesThree"></apexchart>
                    </div>
                    </div>

                </div>
            </div>

            <div class="chart_class" style="margin-top:20px;min-height:500.68px">
                <div class="pt-4 pl-4">
                    <h4> 2020/2021 Clearanace Demographic Analysis</h4>
                </div>
                <v-divider></v-divider>
                <div style="padding-top:50%"
        class="fill-height"
        align-content="center"
        justify="center" v-if="!dataLoaded"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
         Sorting Data for analysis
        </v-col>
        <v-col cols="12">
          <v-progress-linear
            color="deep-purple accent-1"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </div>
      <div v-else>
               
                <div class="chart_one">
                    
                    <h6 class="pl-4">Gender</h6>
                    <div id="chart">
                        
                        <apexchart type="donut" width="270" :options="chartOptionsone1" :series="seriesone1"></apexchart>
                    </div>
                    <h6 class="pl-4">Special needs students</h6>
                    <div id="chart">
                        <apexchart type="donut" width="270" :options="chartOptionsTwo1" :series="seriesTwo1"></apexchart>
                    </div>
                    <!-- <h6 class="pl-4">Nationality</h6>
                    <div id="chart">
                        <apexchart type="donut" width="270" :options="chartOptionsThree1" :series="seriesThree1"></apexchart>
                    </div> -->
                </div>
            </div>
        </div>
        </div>
        
    </div>
    <div class="">
        <div class="col-lg-12">
            <div class="bar_chart">
                <div class="pl-4">All Time Admission Analysis</div>
<v-divider></v-divider>
                <div id="chart">
                    <apexchart type="bar" height="430" :options="chartOptions" :series="series"></apexchart>
                </div>
            </div>
        </div>
    </div>
    <div class="">
        <div class="col-md-12">
            <div class="table_clear">
                <v-card>
                    <v-card-title>
                        <div class="cardTableHeader">2020/2021 Last 10 Admited Students</div>
                        <v-spacer></v-spacer>
                        <!-- <button class="download_b" @click="csvExport2(csvData2)" type="submit"> <span style="margin-right: 4px">
                            <img src="../assets/download.svg" alt="" srcset="">
                            </span> Download</button> -->
                        <!-- <v-pagination v-model="clearancepage" :length="clearancepageCount"></v-pagination> -->
                    </v-card-title>
                    <v-divider style="margin-top:0"></v-divider>
                    <v-row
        class="fill-height"
        align-content="center"
        justify="center" v-if="!dataLoaded"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
         Sorting last 10 admited students
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="deep-purple accent-1"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
                    <v-data-table :page.sync="clearancepage" @page-count="clearancepageCount = $event" :headers="headersclearance" :items="addmitedDeserts" items-per-page="10" :search="search" class="px-5" v-else>

                        <template v-slot:item.surname="{item}">
                                <span>{{item.surname}} {{item.firstName}} {{item.lastName}}</span>
                            </template>

                            <template v-slot:item.faculty="{item}">
                                <span>{{item.faculty.name}}</span>
                            </template>

                            <template v-slot:item.department="{item}">
                                <span>{{item.department.name}}</span>
                            </template>

                    </v-data-table>
                </v-card>
            </div>
        </div>



        <div class="col-md-12">
            <div class="table_clear">
                    
               
                <v-card >
                    
                    <v-card-title>
                       <div class="cardTableHeader">2020/2021 Last 10 Cleared Students</div>
                        <v-spacer></v-spacer>
                        <!-- <button @click="csvExport3(csvData3)" class="download_b" type="submit"> <span style="margin-right: 4px">
                            <img src="../assets/download.svg" alt="" srcset="">
                            </span> Download</button> -->
                        <!-- <v-pagination v-model="pageaccepted" :length="acceptedpageCount"></v-pagination> -->
                    </v-card-title>
                    <v-divider style="margin-top:0"></v-divider>

                    <v-row
        class="fill-height"
        align-content="center"
        justify="center" v-if="!dataLoaded"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
         Sorting last 10 cleared students
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="deep-purple accent-1"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>

                    <v-data-table :page.sync="pageaccepted" @page-count="acceptedpageCount = $event" :headers="acceptedheaders" :items="clearedDeserts" items-per-page="10" :search="search" class="px-5" v-else>
                    
                        <template v-slot:item.department="{item}">
                                <span>{{item.department.name}}</span>
                            </template>

                            <template v-slot:item.faculty="{item}">
                                <span>{{item.faculty.name}}</span>
                            </template>
                    
                    </v-data-table>
                </v-card>
            </div>
        </div>
    </div>
    <div class="row">
       
    </div>
</div>
</div>
               </div>

</div>
</template>

<script>
import Swal from 'sweetalert2'
import axios from 'axios'
export default {
    data() {
        return {
            dataLoaded:false,
            series: [{
                data: [89]
            }, {
                data: [90]
            }],
            chartOptions: {
                chart: {
                    colors: ['#4A869F'],
                    background: '#F4F7F9',
                    type: 'bar',
                    height: 430
                },
                legend: {
                    show: false,
                    showForSingleSeries: false,
                    customLegendItems: [],
                    markers: {
                        fillColors: ['#4A869F', '#775DD0']
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: false,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        colors: ['#fff']
                    }
                },
                stroke: {
                    show: true,
                    width: 3,
                    colors: ['#fff']
                },
                tooltip: {
                    shared: true,
                    intersect: false
                },
                xaxis: {
                    categories: [],
                },
            },

            // ANALYSIS
            seriesone: [],
            chartOptionsone: {
                chart: {
                    width: 250,
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270
                    }
                },
                labels: ["Male", "Female"],
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'gradient',
                },
                legend: {
                    formatter: function (val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex]
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]

            },

            seriesone1: [],
            chartOptionsone1: {
                chart: {
                    width: 250,
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270
                    }
                },
                labels: ["Male", "Female"],
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'gradient',
                },
                legend: {
                    formatter: function (val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex]
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]

            },

            seriesone: [],
            chartOptionsone: {
                chart: {
                    width: 250,
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270
                    }
                },
                labels: ["Male", "Female"],
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'gradient',
                },
                legend: {
                    formatter: function (val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex]
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]

            },
            seriesThree: [],
            chartOptionsThree: {
                chart: {
                    width: 250,
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270
                    }
                },
                labels: ["Foreign", "Nigerian"],
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'gradient',
                },
                legend: {
                    formatter: function (val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex]
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]

            },
            seriesTwo: [],
            chartOptionsTwo: {
                chart: {
                    width: 350,
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270
                    }
                },
                labels: ["Needs", "No Needs"],
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'gradient',
                },
                legend: {
                    formatter: function (val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex]
                    }
                },
                responsive: [{
                    breakpoint: 680,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'top'
                        }
                    }
                }]

            },
            seriesTwo1: [],
            chartOptionsTwo1: {
                chart: {
                    width: 350,
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        startAngle: -90,
                        endAngle: 270
                    }
                },
                labels: ["Needs", "No Needs"],
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'gradient',
                },
                legend: {
                    formatter: function (val, opts) {
                        return val + " - " + opts.w.globals.series[opts.seriesIndex]
                    }
                },
                responsive: [{
                    breakpoint: 680,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'top'
                        }
                    }
                }]

            },
            search: '',          
            // 
            clearancepage: 1,
            clearancepageCount: 0,
            headersclearance: [{
                    text: 'Entry No',
                    align: 'start',
                    value: 'regNumber',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Names',
                    value: 'surname',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Faculty',
                    value: 'faculty',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Department',
                    value: 'department',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Batch',
                    value: 'admissionBatch',
                    class: 'grey lighten-4'
                }, {
                    text: 'Entry Mode',
                    value: 'entryPoint',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Date Admitted',
                    value: 'date_created',
                    class: 'grey lighten-4'
                },
                
            ],
            clearedDeserts: [],

            // 
            pageaccepted: 1,
            acceptedpageCount: 0,
            acceptedheaders: [{
                    text: 'Entry ID',
                    align: 'start',
                    sortable: false,
                    value: 'regnumber',
                    class: 'grey lighten-4'
                },
                {
                    text: 'School Reg',
                    value: 'schoolReg',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Surname',
                    value: 'surname',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Name',
                    value: 'name',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Othernames',
                    value: 'othername',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Faculty',
                    value: 'faculty',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Department',
                    value: 'department',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Cleared By',
                    value: 'clearedBy',
                    class: 'grey lighten-4'
                },
                {
                    text: 'Date Cleared',
                    value: 'date_admitted',
                    class: 'grey lighten-4'
                },
               
            ],
            addmitedDeserts: [],
        }
    },
      beforeMount() {
        this.getEveryotherData();
    // this.admissionanalysis(this.$router.currentRoute.params.admissionAnalysis);

    },
created(){
        this.departmentName = this.$route.params.deptname
},
 mounted() {
   

        // this.renderChart(this.chartdata, this.options)
    },
    computed: {
        csvData() {
            return this.desserts.map(item => ({
                ...item,
            }));
        },

        // Admission Clearance
        csvData2() {
            return this.dessertsclearance.map(item => ({
                ...item,
            }));
        },

        csvData3() {
            return this.accepteddesserts.map(item => ({
                ...item,
            }));
        }
    },


    methods: {

// admissionanalysis(x) {
//             this.loopData = x.data
//         },
        
getLAstUploaded(x){
   
  //const sorted = x.sort((a, b) => new Date(a.date_created) - new Date(b.date_created))
return x.slice(0, 1);
},
    theFormat(number) {
            return number;
        },
        theFormatm(number) {
            return this.formatPrice(number);
        },
        completed() {
            console.log('Animation ends!');
        },

 async getEveryotherData() {
            const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT + '/maindashAnalysis';
            const admissionAnalysis = await axios({
                method: 'POST',
                url: url,
                data: {
                    staffId:`${this.$logedIndataId}`,
                    session: '2023_2024'
                }
            }).catch(e => {
                console.log(e);
            })

            if (admissionAnalysis.data.code == '00') {
            
                console.log(admissionAnalysis.data.data)
                this.seriesone = admissionAnalysis.data.data.genderAdmited
//// Cleare gender analysis
this.seriesone1 = admissionAnalysis.data.data.gendercleared

                this.chartOptions.xaxis.categories=admissionAnalysis.data.data.allTimeYears;
                this.series[0].data.push(899);
                this.series[1].data.push(300)
//// Admited Special Needs
                this.seriesTwo= admissionAnalysis.data.data.specialNeedsAdmited
                //// Cleared Special Needs
                this.seriesTwo1= admissionAnalysis.data.data.specialNeedsCleared
//Nigeria / Foreing Students
                this.seriesThree= admissionAnalysis.data.data.admitedNationality

                this.addmitedDeserts = admissionAnalysis.data.data.last10Adm
                this.clearedDeserts = admissionAnalysis.data.data.last10Cl
this.dataLoaded = true
            }
            console.log(this.chartOptions.xaxis.categories);
        }

        // csvExport(arrData) {
        //     if (this.desserts.length == 0) {
        //         Swal.fire({
        //             icon: "info",
        //             text: "Table Is Empty",
        //             width: 400,
        //         });
        //     } else {
        //         let csvContent = "data:text/csv;charset=utf-8,";
        //         csvContent += [
        //                 Object.keys(arrData[0]),
        //                 ...arrData.map(item => Object.values(item))
        //             ]
        //             // .trim()
        //             .join("\n")
        //             .replace(/(^\[)|(\]$)/gm, "");

        //         const data = encodeURI(csvContent);
        //         const link = document.createElement("a");
        //         link.setAttribute("href", data);
        //         link.setAttribute("download", "studentlist.csv");
        //         link.click();
        //     }
        // },

        // csvExport2(arrData2) {
        //     if (this.dessertsclearance.length == 0) {
        //         Swal.fire({
        //             icon: "info",
        //             text: "Table Is Empty",
        //             width: 400,
        //         });
        //     } else {
        //         let clearancecsvContent = "data:text/csv;charset=utf-8,";
        //         clearancecsvContent += [
        //                 Object.keys(arrData2[0]),
        //                 ...arrData2.map(item => Object.values(item))
        //             ]
        //             // .trim()
        //             .join("\n")
        //             .replace(/(^\[)|(\]$)/gm, "");

        //         const data = encodeURI(clearancecsvContent);
        //         const link = document.createElement("a");
        //         link.setAttribute("href", data);
        //         link.setAttribute("download", "clearance.csv");
        //         link.click();
        //     }
        // },

        // csvExport3(arrData) {
        //     if (this.accepteddesserts.length == 0) {
        //         Swal.fire({
        //             icon: "info",
        //             text: "Table Is Empty",
        //             width: 400,
        //         });
        //     } else {
        //         let csvContent = "data:text/csv;charset=utf-8,";
        //         csvContent += [
        //                 Object.keys(arrData[0]),
        //                 ...arrData.map(item => Object.values(item))
        //             ]
        //             // .trim()
        //             .join("\n")
        //             .replace(/(^\[)|(\]$)/gm, "");

        //         const data = encodeURI(csvContent);
        //         const link = document.createElement("a");
        //         link.setAttribute("href", data);
        //         link.setAttribute("download", "studentlist.csv");
        //         link.click();
        //     }
        // }
    },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
.first_txt {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #4F4F4F;
    margin: 20px 0;
}

.bar_chart {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 20px 0;
}

.chart_class {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border-radius: 8px;
    padding-bottom: 2px;

    h4 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #4F4F4F;
    }

    h6 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #646262;
    }
}

#chart {
    background: #FFFFFF;
    border: 1px solid #F0F2F3;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin: 20px;
}


</style>

<template>
<div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc">
            <span style="color:#777; font-size:18px;padding:10px">Admited student change of course</span>
        </div>
    </div>
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
        <v-row v-if="exist">
            <div style="margin:auto; padding:20px; margin-top:100px" class="col-md-5">
                <v-card elevation="3" height="300">
                    <v-card-title class="text-h7" style="color:#666">
                        Find Admited Student
                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>

                    <v-col cols="9" style="margin:auto;padding-top:45px" pa-4>
                        <v-text-field outlined label="Student Identity" v-model="regNumber" dense></v-text-field>
                        <v-btn color="primary" @click="submit" elevation='2' class="col-md-12" :disabled="regNumber==''">Find</v-btn>
                    </v-col>
                </v-card>
            </div>
        </v-row>

    <div v-else>
        <v-card class="pa-12" style="background: transparent" elevation="0">
            <v-row>
                <v-col class="col-sm-12 col-md-6 col-lg-7">
                    <div class="info_wrap">
                        <div class="info_wrap--header py-3 px-5">
                            Student Information
                        </div>
                        <div class="info_wrap--container">
                            <v-row class="text-left">
                                <v-col class="py-1 " cols="12"><strong class="mr-7">Reg Number:</strong>
                                    <span style="text-align: left">{{
                      studentInfo.regnumber
                    }}</span>
                                </v-col>

                                <v-col class="py-1" cols="12"><strong class="mr-7">First Name:</strong>
                                    {{ studentInfo.surname }}
                                </v-col>
                                <v-col class="py-1" cols="12"><strong class="mr-7">Last Name:</strong>
                                    {{ studentInfo.name }}
                                </v-col>
                                <v-col class="py-1" cols="12"><strong class="mr-7">Department:</strong>
                                    {{ studentInfo.department.name }}
                                </v-col>
                                <v-col class="py-1" cols="12">
                                    <strong class="mr-7">Faculty: </strong>
                                    <span>{{ studentInfo.faculty.name }}</span>
                                </v-col>
                                <v-col class="py-1" cols="12">
                                    <strong class="mr-7">Gender: </strong>
                                    <span>{{
                      studentInfo.gender === "F" ? "Female" : "Male"
                    }}</span>
                                </v-col>
                                <v-col class="py-1" cols="12">
                                    <strong class="mr-7">State: </strong>
                                    <span>{{ studentInfo.state }}</span>
                                </v-col>
                                <v-col class="py-1" cols="12">
                                    <strong class="mr-7">L.G.A:</strong>
                                    <span style="text-align: center">{{
                      studentInfo.lga
                    }}</span>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
                <v-col class="col-sm-12 col-md-6 col-lg-5">
                    <div class="info_wrap">
                        <div class="info_wrap--header py-3 px-5">
                            Change course
                        </div>
                        <div class="info_wrap--container">
                            <div style=" margin-top: 2rem ">
                                <v-form style="">
                                    <label for=""><strong>Select Department to change</strong></label>
                                    <!-- <v-select
                      label="Select Department"
                      outlined
                      :items="departments"
                      :
                      v-model="department"
                    ></v-select> -->
                                    <v-autocomplete item-text="name" item-value="_id" v-model="department" :items="departments" label="Department to change to"> </v-autocomplete>
                                    <v-btn @click="changeCourse" block style=" background: #21618C; color: #fff; height: 50px		">Change</v-btn>
                                </v-form>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// import Swal from "sweetalert2";
// import axios from "axios";

export default {
    data() {
        return {
            exist: true,
            regNumber: "",
            departments: [],
            department: "",
            studentInfo: null,
        };
    },

    beforeMount() {
        this.populateDepartment();
    },
 mounted() {
        // remove modal on entry
        this.hideActivityLoader()
    },
    methods: {
        getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
},
ShowActivityLoader() {    
  if (document.querySelector("#pleaseWaitDialog") == null) {
      var modalLoading = '<div class="modal" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false" role="dialog" style="margin-top:40vh">\
          <div class="modal-dialog" style="background-color:transparent;border:none">\
              <div class="modal-content" style="background-color:transparent; text-align:center;border:none">\
                  <div class="modal-body">\
                      <div class="spinner-border" role="status" style="color:#fff">\
  <span class="sr-only"></span>\
</div>\
<div style="color:#fff">Loading ...</div>\
                  </div>\
              </div>\
          </div>\
      </div>';
      $(document.body).append(modalLoading);
  }

  $("#pleaseWaitDialog").modal("show");
},
hideActivityLoader() {
  $("#pleaseWaitDialog").modal("hide");
},

parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
},
        async populateDepartment() {
            try {
                const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2 + '/get-department'
                axios.post(url)
                    .then(response => {
                        if (response.data.code == '00') {
                            this.departments = response.data.message
                //             console.log(response.data);
                //            response.data.message.forEach((el) =>
                //    this.departments.push({
                //      text: el.name,
                //     value: `${el._id}-${el.faculty}`
                //     }) )
                        }
                    })
                // const res = await axios.POST(url);
                // console.log(res);
                // res.data.data.forEach((el) =>
                //   this.departments.push({
                //     text: el.name,
                //     value: `${el._id}-${el.faculty}`,
                //   })
                // );
            } catch (err) {
                console.log(err);
            }
        },

        async changeCourse() {
            this.ShowActivityLoader()
            try {
                if (!this.department)
                    return Swal.fire({
                        icon: "error",
                        text: "Please Select a Department",
                        title: "Oops..",
                        width: 400,
                    });
                // console.log(this.department, this.studentInfo.regNumber);
                const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT + '/changeCourse'
                const res = await axios.post(url, {
                    department: this.department,
                    regNumber: this.studentInfo.regnumber,
                });
                // console.log(res);
                if (res.data.code === "00") {
                    this.department = "";
                    this.studentInfo = null;
                    this.exist = true;
                    return Swal.fire({
                        icon: "success",
                        title: "Congratlations!!",
                        text: "Course Successfully Changed",
                        timer: 1000,
                        width: 400,
                    });
                }
                this.hideActivityLoader()
            } catch (err) {
                this.hideActivityLoader()
                console.log(err);
            }
        },
        async submit() {
                        this.ShowActivityLoader()

            try {
                if (!this.regNumber)
                    return Swal.fire({
                        icon: "error",
                        text: "Please Enter Reg Number",
                        title: "Oops..",
                        width: 400,
                    });
                const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT + "/find_admited"
                const res = await axios.post(url, {
                    entryData: this.regNumber,
                    year: '2023_2024'
                });
                console.log(res);
                if (res.data.code === "00") {
                   
                    this.studentInfo = res.data.message;
                    if(this.studentInfo.status === 5){
                    this.regNumber = "";
                    this.exist = false;
                    Swal.fire({
                        icon: "success",
                        title: "success",
                        showConfirmButton: false,
                        timer: 1000,
                        width: 350,
                    });
                }else if(this.studentInfo.status === 6){
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        width: 350,
                        text: "Student has been cleared, so you cannot effect change of course"
                    });
                }
                } else if (res.data.code == "E404") {
                    Swal.fire({
                        icon: "error",
                        title: "Oops..!",
                        text: "Incorrect Registration Number",
                        width: 350,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops..!",
                        text: "Something went wrong! Try again",
                        width: 350,
                    });
                }
                this.hideActivityLoader()
            } catch (err) {
                this.hideActivityLoader()
                console.log(err);
            }
            this.hideActivityLoader()

        },
    },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@600&family=Poppins:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Proxima+Nova");

strong {
    max-width: 100px;
    display: inline-block;
}

* {
    box-sizing: border-box;
}

.info_wrap {
    height: 400px;
    background-color: #fff;
}

.info_wrap--header {
    background-color: #d6ecf5;
    color: #006794;
    font-weight: bold;
}

.info_wrap--container {
    padding: 2rem 2.5rem;
}

.designation {
    background-color: #fff;
    padding: 30px;
    margin-top: 30px;
    border-radius: 5px;
}

.search_input {
    width: 100%;
    height: 430px;
    background: #ffffff;
    border-radius: 8px;

    input {
        margin-top: 25%;
        width: 100%;
        height: 60px;
        background: #ffffff;
        border: 0.5px solid #d1d5df;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px;
    }

    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        width: 100%;
        height: 60px;
        background: #17517e;
        border-radius: 5px;
        font-family: Mulish;
        font-size: 18px;
        line-height: 21px;
        color: #ffffff;

        &:hover {
            background: #1e69a1;
        }
    }

    @media (max-width: 700px) {
        input {
            width: 100%;
            height: 38px;
        }
    }
}
</style>

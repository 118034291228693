import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import Home from '@/components/Admissiondashboard.vue'
import clearancebyDept from '../components/dashboardComponents/clearancebyDept'
import cardAnalytics from '../components/dashboardComponents/cardnalytics'
import eclearnaceReport from '../components/eclearanceList'
import admitedReport from '@/components/listAdmitted'
import addStudent from '@/components/addstudent'
import approveAddStudent from '@/components/approveUploadedStudent';
import approveAdmission from '@/components/batchApproval';
import openUpBatch from "@/components/studentListApproval";
import supplementaryReport from '../components/supplementary'
import clearApplicant from '@/components/clearStudent'
import editcandidate from '@/components/ChangeNewData.vue'
import changeofcourse from '@/components/changeOfCourse.vue'
//Upload Admission
import uploadAdmissions from '@/components/uploadadmissionLIst'

import { fetchactiveSets,allDepartments, getCookie,menusAsigned,parseJwt} from '@Appmart/utility';

Vue.use(VueRouter)




const routes = [
  {
    path: '/admissions/dashboard',
    name: 'Home',
  
    component: Home,
    meta:{
requiresAuth:true,check_link:true
    },
  children:[
      {
        path: '/admissions/dashboard',
        components: {default: clearancebyDept,cardAnalytics},
        name: "clearancebyDept"
    }
  ]
  },
  {
    path: '/admissions/listadmitted',
    name: 'listadmited',
    component: admitedReport,
    meta:{
requiresAuth:true,check_link:true
    },
        beforeEnter: (to, from, next) => {
          const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-department'                
          const url1 = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-faculty_standalone' 
          const url2 = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-program'
          const url3 = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-entrymode'

          const requestOne = axios.post(url);
          const requestTwo = axios.post(url1);
          const requestThree = axios.post(url2);
          const requestFour = axios.post(url3);

          axios.all([requestOne, requestTwo,requestThree,requestFour])
            .then(axios.spread((...responses) => {
              const responseOne = responses[0]
              const responseTwo = responses[1]
              const responseThree = responses[2]
              const responseFour = responses[3]

              console.log(responseOne);
              console.log(responseTwo);              console.log(responseThree);

              to.params.response = responseOne.data;
              to.params.response1 = responseTwo.data;
              to.params.response2 = responseThree.data;
              to.params.response3 = responseFour.data;


              next({ params: to.params });
            }))
            .catch(e => {
              next(false)
            })
        },
  },
  {
    path: "/admissions/eclearancereport",
    name: "eclearnacereport",
    meta:{
      requiresAuth:true, check_link:true
          },
          beforeEnter: (to, from, next) => {
            const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-department'                
            const url1 = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-faculty_standalone' 
            const url2 = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-program'
            const url3 = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-entrymode'
  
            const requestOne = axios.post(url);
            const requestTwo = axios.post(url1);
            const requestThree = axios.post(url2);
            const requestFour = axios.post(url3);
  
            axios.all([requestOne, requestTwo,requestThree,requestFour])
              .then(axios.spread((...responses) => {
                const responseOne = responses[0]
                const responseTwo = responses[1]
                const responseThree = responses[2]
                const responseFour = responses[3]
  
                console.log(responseOne);
                console.log(responseTwo);              console.log(responseThree);
  
                to.params.response = responseOne.data;
                to.params.response1 = responseTwo.data;
                to.params.response2 = responseThree.data;
                to.params.response3 = responseFour.data;
                next({ params: to.params });
              }))
              .catch(e => {
                next(false)
              })
          },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:eclearnaceReport
  },
  {
    path: "/admissions/adupload",
    name: "uploadAdmission",
    meta:{
      requiresAuth:true, check_link:true
          },
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:uploadAdmissions
  },
  {
    path: "/admissions/approveAdmission",
    name: "approveAdmissionlist",
    meta:{
      requiresAuth:true, check_link:true
          },
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:approveAdmission
  },
  {
    path: "/admissions/admissionbatch/:id",
    name: "studentBatch",
    meta:{
      requiresAuth:true
          },
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:openUpBatch
  },
  
  
  {
    path: "/admissions/addstudent",
    name: "addStudent",
    meta:{
      requiresAuth:true, check_link:true
          },
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:addStudent
  },

  {
    path: "/admissions/approveAddstudent",
    name: "approveAddStudent",
    meta:{
      requiresAuth:true, check_link:true
          },
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:approveAddStudent
  },


////////SUPLEMENTARY VIEW
{
  path: "/admissions/suplementaryReport",
  name: "supplemtaryshop",
  meta:{
    requiresAuth:true, check_link:false
        },
        // beforeEnter: (to, from, next) => {
        //   const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT2+'/get-department'
        //   axios.post(url)
        //     .then(response => {
        //       if(response.data.code =='00'){              
        //       console.log(response.data);
        //       to.params.response = response.data;
        //       next({ params: to.params });
        //       }
        //     })
        //     .catch(e => {
        //       next(false)
        //     })
        // },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component:supplementaryReport
},

{
  path: "/admissions/editcandidate",
  name: "editcandidate",
  meta:{
    requiresAuth:true, check_link:true
        },
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component:editcandidate
},
{
  path: "/admissions/changeops",
  name: "changeofcourse",
  meta:{
    requiresAuth:true, check_link:true
        },
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component:changeofcourse
},

{
  path: '/admissions/clearapplicant',
  name: 'clearApplicant',
  meta:{
    requiresAuth:true,check_link:true
        },
  // beforeEnter: async (to, from, next) => {
  //   const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+'/getAdmitedInfo'
  //   console.log(url);
  //   /**
  //    * 
  //    * note to do
  //    * Find a way to get session into this place
  //    */
  //    const admissionAnalysis =  await axios({
  //         method: 'POST',
  //         url: url,
  //         data: {
  //             session: '2021-2022'
  //         }
  //     }).catch(e =>{
  //       console.log(e);
  //       alert('Something went wrong')
  //           next(false)
  //     })       

  //     if(admissionAnalysis.data.code =='00'){
  //           to.params.admissionAnalysis = admissionAnalysis.data;
  //           next({ params: to.params });
  //       }else{
  //         alert('Something went wrong')
  //         next(false)
  //       }
  //       },
  component: clearApplicant
},
]



const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})



  router.beforeEach(async(to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
    console.log(to.fullPath);  
    /**
     * before we can enter any route on this module, we will have to get the active session as well as get the menus for this operation
     */
     let name = getCookie("-X_Zka");
     const tokendecoded = parseJwt(name);
  
     const activeSetting = await fetchactiveSets().then(activeSet => {
      return activeSet;
    }).catch(e => {
      console.log(e);
      alert('Something went wrong')
      next(false)
    })
    const getmyMenus = await menusAsigned('userExec',activeSetting.data.data.program._id,tokendecoded.staffid).then(activeSet => {
      return activeSet;
    }).catch(e => {
      console.log(e);
      alert('Something went wrong')
      next(false)
    })
  
    console.log('found',getmyMenus.data.message.deptAssigned);

    if(to.matched.some(record => record.meta.check_link)) {
    const checkifMenuIsBuilt = await getmyMenus.data.message.deptAssigned.find(menuId =>menuId.menuLink ==to.fullPath);
  //const checkifMenuIsBuilt = true;
    console.log('found_menu',checkifMenuIsBuilt);
  if(checkifMenuIsBuilt){
    to.params.activeSetting = activeSetting;
    to.params.getmyMenus = checkifMenuIsBuilt;
    next({ params: to.params })
  }else{
      window.location.href =`${window.location.origin}/#/auth`
    return
  }
}else{
  next({ params: to.params })
}
    }
//   console.log(to);  
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     let name = getCookie("-X_Zka");
//     console.log(name);
//     if(!name){
//   window.location.href =`${window.location.origin}/#/auth3`;
// }else{
//   if(to.matched.some(record1 => record1.meta.check_link)) {
//     const jwtDecode = parseJwt(name)
//     // check if user is eligible for the menu
//   var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
//    axios.post(url,{
//     staffid:jwtDecode.staffid,
//     menuPath:to.fullPath
//    })
//                 .then(response => {
//                   if(response.data.code =='00'){
//                     console.log(response.data.message);
//                     //if(response.data.message == true){
//                       next()
//                    // }else{
//                      // window.location.href =`${window.location.origin}/#/auth1`;
//                    // }
//                   }else{
//                     window.location.href =`${window.location.origin}/#/auth2`;
                    
//                   }
//                 })
//                 .catch(e => {
//                   console.log(e);
//                   //window.location.href =`${window.location.origin}/#/auth`;
//                   //alert('Something went wrong')
//                 })  
//   }else{
//     next()
//   }
// }
//   }else{
//     if(to.matched.some(record => record.meta.check_link)) {

//       const jwtDecode = parseJwt(name)
//       // check if user is eligible for the menu
//     var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
//      axios.post(url,{
//       staffid:jwtDecode.staffid,
//       menuPath:to.fullPath
//      })
//                   .then(response => {
//                     if(response.data.code =='00'){
//                       console.log(response.data.message);
//                       if(response.data.message == true){
//                         next()
//                       }else{
//                         window.location.href =`${window.location.origin}/#/auth4`;
//                       }
//                     }else{
//                       window.location.href =`${window.location.origin}/#/auth5`;
                      
//                     }
//                   })
//                   .catch(e => {
//                     window.location.href =`${window.location.origin}/#/auth6`;
//                     alert('Something went wrong')
//                   })    }else{
//     next()
//     }
//   }
//   next()
})

export default router


<template>
  <div class="home">
    <div class="row">
      <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
        <span style="color:#777; font-size:18px;padding:5px;">Settings</span>/<span style="padding:5px; color:#555">Approve Student</span>
      </div>
    </div>
  
    <v-dialog v-model="dialogRegset" width="600">
      <v-card>
        <v-card-title style="color:#666; font-size:18px">
          {{ actionType == '1' ? 'Approve' : 'Reject' }}
          <v-spacer></v-spacer>
          <v-icon @click="dialogRegset=false">mdi-close-circle</v-icon>
  
        </v-card-title>
        <v-divider style="margin-top:0px"></v-divider>
  
        <v-card-text>
  
          <v-row>
            <div class="col-md-6 px-8">
             
             
              <p>
                <strong>Student Name : {{ item.name }}</strong>
              </p>
             
              <p>
                <strong>Phone : {{ item.phone }}</strong>
              </p>
              <p>
                <strong>Email : {{ item.email }}</strong>
              </p>
              <p>
                <strong>Gender : {{ item.gender }}</strong>
              </p>
              <p>
                <strong>Date of birth : {{ item.dob }}</strong>
              </p>
              <p>
                <strong>State : {{ item.state}}</strong>
              </p>
              <p>
                <strong>LGA : {{ item.lGA }}</strong>
              </p>

             
         

              <p v-if="item.supportingDocument">
                <a rel="noopener noreferrer" target="_blank" :href="formatLink(item.supportingDocument)">Supporting Document</a>
              </p>
            </div>
            
            <div class="col-md-6 px-8">
              <p>
                <strong>Regnumber : {{ item.regNumber }}</strong>
              </p>
              <p>
                <strong>Admission Number : {{ item.admissionNumber }}</strong>
              </p>
              <p>
                <strong>Program : {{ item.pname }}</strong>
              </p>
      
              <p>
                <strong>Faculty : {{ item.fname }}</strong>
              </p>
              <p>
                <strong>Department : {{ item.dname }}</strong>
              </p>

              
             
       
              <p>
                <strong>Level : {{ item.level }}</strong>
              </p>
              <p>
                <strong>Entry Mode : {{ item.entryMode }}</strong>
              </p>
             
             
            </div>
<div  class="mb-5">
  <strong>Reason: </strong>
            {{ item.reason }}
</div>
          

          </v-row>
          <v-card-actions>
  
            <v-btn color="#c0e7fe" v-if="actionType
             == '1'" @click="openOtpSingle" class="col-md-5">Approve</v-btn>
            <v-btn color="#c0e7fe" v-if="actionType
             == '2'" @click="openOtpSingle" class="col-md-5">Reject</v-btn>
          </v-card-actions>
        </v-card-text>
  
      </v-card>
    </v-dialog>


  
    <v-dialog v-model="showOtp" persistent max-width="400">
      <v-card>
        <v-card-title style="color:#666; font-size:18px">
          Enter Opt
          <v-spacer></v-spacer>
          <v-icon @click="showOtp=false">mdi-close-circle</v-icon>
  
        </v-card-title>
        <v-divider style="margin-top:0px"></v-divider>
  
        <div class="pa-6">
  
          <v-text-field type="number" v-model="otp" outlined prepend-inner-icon="mdi-key" label="Enter Otp" single-line dense></v-text-field>
        </div>
        <v-card-actions>
  
          <v-btn v-if="actionType == '1'" color="#c0e7fe" @click="process" :loading="loading" class="col-md-5">Proceed</v-btn>

<v-btn v-if="actionType == '2'" color="#c0e7fe" @click="process"  class="col-md-5">Reject</v-btn>
  

        </v-card-actions>
      </v-card>
    </v-dialog>
  
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
  
      <div class=" mb-8">
        <v-card v-if="showform" style="margin: 0 auto" class="pa-12" width="700">
          <div>
  
    
            <v-text-field outlined dense v-model="filter.regNumber" label="Enter Reg Number"></v-text-field>

            <v-autocomplete dense :items="programs" v-model="filter.program" outlined label="Select Program"></v-autocomplete>


            <v-autocomplete dense :items="faculties" v-model="filter.faculty" outlined label="Select Faculty"></v-autocomplete>


            <v-autocomplete dense :items="departments" v-model="filter.department" outlined label="Select Department"></v-autocomplete>
  
            
            <v-row>
              <v-col sm="12" md="6">
                <v-menu ref="menuu" v-model="menuu" :close-on-content-click="false" :return-value.sync="filter.datefrom" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="filter.datefrom" label="Date From" prepend-icon="mdi-calendar" readonly dense outlined v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="filter.datefrom" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menuu.save(filter.datefrom)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu ref="menux" v-model="menux" :close-on-content-click="false" :return-value.sync="filter.dateto" transition="scale-transition" offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="filter.dateto" label="Date To" prepend-icon="mdi-calendar" readonly dense outlined v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="filter.dateto" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menux = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menux.save(filter.dateto)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

                  </v-col>
            </v-row>
           
  
            
            <v-btn color="#c0e7fe" block @click="fetchStudents" class="ma-auto pa-3">Next</v-btn>
            <!-- <v-btn color="primary" class="pa-3" block @click="shownext=true">Next</v-btn> -->
          </div>
        </v-card>

        <v-card v-else>
          <v-row>
            <v-col md="12">
              <v-card class="pa-8" elevation="0">
                <div>
                  <button style="padding: .9rem; background: #ccc" @click="back">
                    <!-- <img src="../../../assets/arrowleft.svg" alt="" srcset="" /> -->
                  </button>
                </div>
                <v-toolbar class="mb-2" flat>
                  <v-toolbar-title>{{datas.length}} Data</v-toolbar-title>
                </v-toolbar>
                <v-data-table  item-key="id" :headers="headers" :items="datas">
                  <template v-slot:item.action="{ item }">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="" text v-bind="attrs" v-on="on">
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="approveSingle(item, '1')">
                          <v-list-item-title>
                            <v-icon class="mr-2" small>
                              mdi-check
                            </v-icon>Approve
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="rejectSingle(item, '2')">
                          <v-list-item-title>
                            <v-icon class="mr-2" small>
                              mdi-cancel
                            </v-icon>Reject
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
             
                </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  // import readXlsxFile from 'read-excel-file';
  export default {
    data() {
      return {
        menu: false,
        filter: {

     
regNumber: '',
        datefrom: '',
        dateto: '',
        program:'',
        department:'',
        faculty: '',
        },
        showform: true,
        faculties: [],
        showOtp: false,
        loading: false,
        dialogRegset: false,
        actionType: '',
        degree: '',
        otp: '',
        selected: [],
       // fname, dname, pname, name, dob, state, lGA, email, entryMode, gender, level
        headers: [
        {
          text: 'Reg Number',
          value: 'regNumber'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Gender',
          value: 'gender'
        },
       
        {
          text: 'Date of Birth',
          value: 'dob'
        }, {
          text: 'Level',
          value: 'level'
        },
        {
          text: 'Phone',
          value: 'phone'
        },
  
        {
          text: 'State',
          value: 'state'
        },
        {
          text: 'LGA',
          value: 'lGA'
        },
        {
          text: 'Entry Mode',
          value: 'entryMode'
        },
        {
          text: 'Year of Admission',
          value: 'yearOfAdmission',
        },
        {
          text: 'Uploaded By',
          value: 'uploby'
        },
        {
          text: "Date Uploaded",
          value: "duploaded"
        },
     
        {
          text: '',
          value: 'action'
        },
        ],
        item: {},
        datas: [],
        programs: [],
      
        departments: [],
        departmentsHolder: [],
        sessions: [],
        session: '',
        payload: [],

      }
    },
    watch: {
 
    "filter.faculty"(val) {
      if(val){

        this.populateRightDepartments(val);
      }
    },


  },
    beforeMount() {
  
      this['menuu'] = false,

this['menux'] = false,
      this.fetchSessions();
      this.fetchDepartments();
      this.fetchProgram();
      this.fetchFaculties();
      
    },
    methods: {
      async fetchFaculties() {
      try {
        const res = await axios.get(process.env.VUE_APP_BaseUrl + '/fetchallfaculty');
        if (res.data.code == '00') {
          res.data.message.forEach(el => {
            this.faculties.push({
              text: el.name,
              value: el._id
            })
          })
        }

      } catch (error) {
        console.log(error);
      }
    },
      back(){
        this.program = '';
        this.department = '';
        this.session = '';
        this.payload = [];
        this.selected = [];
        this.showform = true;
        this.showOtp = false;
        this.dialogRegset = false;
      },
      formatLink(link){
        return `${process.env.VUE_APP_BaseUrl}${link}`
      },
     async process(){
      if(!this.otp) {
        return  Swal.fire({icon: 'error', text: 'Please enter otp', title: 'Oops..'})
      }else{
          try {
        const payload = {
          students: this.payload, actionType: this.actionType, otp: this.otp
        }

        const res = await axios.post(process.env.VUE_APP_BaseUrl + '/approve-reject-students',payload);

        if(res.data.code == 's200'){
        this.datas = this.datas.filter(el => {
          for (let i = 0; i < this.payload.length; i++) {
            const el2 = this.payload[i];

            if(el.id == el2.id){
              continue;
            }else{
              return el; 
            }
          }
         

        })

        Swal.fire({icon: 'success', text: `Students successfully ${this.actionType == '1' ? 'Approved' : 'Rejected'}`, title: 'Oops..'});
          this.showOtp = false;
          this.otp = '';
          this.selected = [];
          this.payload = [];
        }else{
          Swal.fire({icon: 'error', text: res.data.message, title: 'Oops..'})
        }
      } catch (error) {
        console.log(error);
      }
      }
    
      },
      approveSingle(item, type ){
        this.otp = '';
        this.payload = [item];
        this.item = item;
        this.actionType = type;
        this.dialogRegset = true;
      },
      rejectSingle(item, type){
        this.otp = '';
        this.payload = [item];
        this.item =item;
        this.actionType = type;
        this.dialogRegset = true;
      },
      openOtpMulti(type){
        this.otp = '';
       this.actionType = type;
       this.payload = [...this.selected];
       this.showOtp = true;
      },
      async fetchStudents(){
        if(!this.filter.datefrom && !this.filter.dateto && !this.filter.department && !this.filter.faculty && !this.filter.regNumber && !this.filter.program) {
          return Swal.fire({icon: 'error', text: 'You can not submit empty form', title: 'Incomplete Form'})
        }else{
          try {
          
this.datas = [];
const payload = this.filter;
          const res = await axios.post(process.env.VUE_APP_BaseUrl + '/fetch-student-approval', {payload});

          
console.log(res)

          if(res.data.code == 's200'){
            if(res.data.message.length !== 0){
              res.data.message.forEach(student => {
              this.datas.push({
                reason: student.reason ? student.reason : ' ',
                id: student._id,
                department: student.department._id,
                dname: student.department.name,
    faculty: student.faculty._id,
    fname: student.faculty.name,
    program: student.program._id,
    pname: student.program.program,
    firstname: student.firstname,
    othernames: student.othernames,
    surname: student.surname,
    gender: student.gender,
    uploby: student.uploadedBy,
duploaded: student.dateUploaded.split('T')[0],
    regNumber: student.regNumber,
      admissionNumber: student.admissionNumber,
      name: `${student.firstname} ${student.othernames}`,
    password: student.password,
    dob: `${student.YOB}-${student.MOB}-${student.DOB}`,
    DOB: student.DOB,
    degree: student.degree,
    MOB: student.MOB,
    yearOfAdmission: student.yearOfAdmission,
    YOB: student.YOB,
    state: student.state,
    newStudent: student.newStudent,
    semester: student.semester,
    lGA: student.lGA,
    email: student.email,
    entryMode: student.entryMode,
    profileUrl: student.profileUrl ,
    noOfResults: student.noOfResults,
    supportingDocument: student.supportingDocument ? student.supportingDocument : '',
    studentId: student.studentId,
        phone: student.phone,
    level: student.level,
              })
            })
            this.showform = false;
            this.filter = {

     
regnumber: '',
        datefrom: '',
        dateto: '',
        program:'',
        department:'',
        faculty: '',
        };
            }else{
              Swal.fire({icon: 'error', text: 'No record was found', title: 'Not Found'});
            }

          }else{
            Swal.fire({icon: 'error', text: res.data.message, title: 'Oops..'})
          }
        } catch (error) {
          console.log(error);
        }
        }
        
      },
      openOtpSingle(){
        this.showOtp= true;
        this.dialogRegset = false;
  
      },

      async fetchDepartments() {
      try {
        const res = await axios.get(process.env.VUE_APP_BaseUrl + "/getalldepartments");
        this.departmentsHolder = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    populateRightDepartments(val) {
      if (val === '') return;
      const departmentDatas = this.departmentsHolder.filter(
        (el) => el.faculty._id === val
      );
      const departments = departmentDatas.map((el) => {
        return {
          text: el.name,
          value: el._id
        }
      });
      this.departments = departments;
    },

    async fetchProgram() {
            try {
                const res = await axios.get('http://localhost:5000/fetchprograms');
                console.log('RESET', res)
                if (res.data.code == '00') {
                    res.data.data.forEach(program => {
                        this.programs.push({
                            text: program.program,
                            value: program._id
                        })
                    });
                } else {
                    console.log("ERROR")
                }
            } catch (error) {
                console.log(error)
            }
        },
  
      async fetchSessions() {
        try {
          const res = await axios.get('http://localhost:5000/fetch-session');
          if (res.data.code === 's200') {
            res.data.message.forEach(session => {
              this.sessions.push({
                text: `${session.academicYear}/${Number(session.academicYear )+ 1}`,
                value: session.academicYear
              })
            });
  
          }
        } catch (error) {
          console.log(error);
        }
      },
  
 
  
    }
  }
  </script>
  
  <style>
  .input-f {
    width: 60px !important;
    display: inline-block;
    background-color: #ccc;
    padding: .4rem;
    border-radius: 3px;
  
  }
  
  .btn-publish {
    font-weight: bolder;
    color: green;
    transition: all ease-in-out .2s;
    margin-right: 15px;
  }
  
  .btn-publish:active {
    transform: scale(0.9);
  }
  
  .btn-import {
    text-transform: capitalize;
    width: 120px;
  }
  </style>
  
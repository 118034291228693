<template>
   <div class="home">
        <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">Approve Admission Upload for batch {{ batchInfo.name }}</span>
        </div>
    </div>
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
  
      <v-row style="margin:0 !important; padding:0 !important">
        <v-col cols="12" style="">
          <div class="row">
            <div class="col-md-6 "> <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Student" single-line hide-details></v-text-field> </div>
            <div class="col-md-6 mb-8">
              <div class="row">
                <v-spacer></v-spacer>
              <div class="col-3">
                    <v-btn class="btn btn-approve" :disabled="selected.length ==0"  block @click="upload">Approve</v-btn>
                  </div>
            </div>
            </div>
        </div>
        <div class="row">
              
          </div>
              <v-data-table  v-model="selected" :headers="headers" item-key="id" :search="search" :items="desserts" show-select :single-select="false">
                <!-- <template v-slot:[`item.sn`]="{ item }">
                        <tr>
                            <td class="">{{desserts.map(function(x) {return x.regNumber; }).indexOf(item.regNumber)+1}}</td>
                        </tr>
                    </template> -->
                <template v-slot:item.data-table-select="{ item, isSelected, select }">
                  <v-simple-checkbox v-if="!item.disabled" :value="isSelected" :readonly="item.disabled" :disabled="item.disabled" @input="select($event)"></v-simple-checkbox>
                </template>
              </v-data-table>
  
          
          </v-col>
     
        </v-row>
      <!-- <v-btn class="mr-2" color="secondry" @click="genOtp">
      Generate otp
    </v-btn>
    <v-btn color="success" @click="upload">
      Approve
    </v-btn> -->
      <!-- <v-dialog v-model="dialog" width="450">
        <v-card class="pa-3" elevation="0">
          <v-card-title style="" class="pa-3">
            <div>
              Enter Otp key
            </div>
          </v-card-title>
          <v-form class="px-5">
            <v-text-field v-model="otp" outlined label="Enter Code"></v-text-field>
  
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn block style="height: 50px; background: #006794; color: #fff" @click="approve">
                Confirm
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog> -->
      <v-dialog v-model="dialog" width="450">
        <v-card>
          <v-card-title style="color:#666; font-size:18px">
            Enter OTP To Continue
            <v-spacer></v-spacer>
            <v-icon @click="closeDialog">mdi-close-circle</v-icon>
  
          </v-card-title>
          <v-divider style="margin-top:0px"></v-divider>
  
          <v-card-text>
            <v-row>
              <div class="col-md-12 pa-8">
                <v-text-field dense v-model="otp" outlined label="Enter Code"></v-text-field>
              </div>
  
            </v-row>
            <v-card-actions>
  
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
            <v-btn v-if="showapprove" block style="height: 50px; background: #006794; color: #fff" @click="approve">
              Approve
            </v-btn>
            <v-btn v-else block style="height: 50px; background: #006794; color: #fff" @click="reject">
              Reject
            </v-btn>
  
              <!-- <v-btn color="#c0e7fe" @click="approve" class="col-md-5 ma-auto">Approve</v-btn> -->
  
              <!-- <button class="next_button" @click="save">
                                              Add
                                          </button> -->
            </v-card-actions>
          </v-card-text>
  
        </v-card>
      </v-dialog>
    </div>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  
  export default {
    data() {
      return {
        otp: '',
        search: '',
        dialog: false,
        dialog1: true,
        selected: [],
        showapprove: false,
        singleSelect: false,
        headers: [
        
        {
            text: 'Reg Number',
            value: 'regnum'
          },
          {
            text: 'Name',
            value: 'name'
          },
          {
            text: 'Faculty',
            value: 'faculty'
          },
          {
            text: 'Department',
            value: 'department'
          },
          {
            text: 'From',
            value: 'from'
          },
          {
            text: 'Date Created',
            value: 'date'
          },
          {
            text: 'Status',
            value: 'status'
          }
        ],
  
        desserts: [],
        batchInfo: {}
      }
    },
    beforeMount() {
      this.batchInfo = JSON.parse(window.atob(this.$route.params.id));
      console.log('student batch',this.batchInfo);
      this.fetchBadgeData();
    },
    methods: {
      closeDialog() {
        this.dialog = false;
        this.otp = '';
      },
      // async genOtp() {
      //   try {
      //     const res = await axios.post("http://127.0.0.1:5000/gen-otp", {
      //       createdBy: 'ac.enendu@unizik.edu.ng',
      //     });
      //     if (res.data.code == '00') {
      //       Swal.fire({
      //         icon: 'success',
      //         title: 'Otp send to phone',
      //         width: 400,
      //       })
      //     }
      //   } catch (error) {
      //     console.log(error);
      //   }
      // },
      async upload() {
        try {
          if (this.selected.length !== 0) {
  
            // await axios.get('http://127.0.0.1:5000/gen-otp');
            this.dialog = true;
            this.showapprove = true;
          } else {
            Swal.fire({
              title: 'Please select a badge',
              icon: 'info'
            });
          }
        } catch (err) {
          console.log(err);
        }
      },
      showreject(){
        if (this.selected.length !== 0) {
            this.dialog = true;
            this.showapprove = false;
            this.showapprove = false;
          } else {
            Swal.fire({
              text: 'Please select a column',
              icon: 'info',
              title: 'Oops..'
            });
          }
      },
      async reject(){
        try {
          
          
          const payload = {
            type: 'single', studentIds: this.selected.map(el => el.id), otp: this.otp, programName: this.batchInfo.programName, session: this.batchInfo.session, batchId: this.batchInfo.id
          }
  
  
          const res = await axios.post(this.$appUrl + '/reject-admission', payload);
  console.log('res', res);
          if(res.data.code == 's200'){
            this.desserts = this.desserts.filter(el => {
              for (let i = 0; i < this.selected.length; i++) {
                const payload = this.selected[i];
  
                if(el.id !== payload.id){
                  return el;
                }
                
              }
            })
  
            Swal.fire({
              title: 'Successful',
              text: 'Rejected successfully',
              icon: 'success'
            });
          }else{
            Swal.fire({
              text: res.data.message,
              title: 'Oops...',
              icon: 'error'
            });
          }
  
          this.dialog = false;
          this.otp = '';
  
          console.log(payload);
        } catch (error) {
          console.log(error);
        }
      },
      async approve() {
        try {
          if (this.otp == '') return Swal.fire({
            text: 'Enter otp',
            icon: 'info'
          })
          console.log(this.selected);
          const res = await axios.post(`${this.$appUrl}/approve-batch-admitted-student`, {
            studentIds: this.selected.map(el => el.id),
            programName: this.batchInfo.programName,
            session: this.batchInfo.session,
            id: this.batchInfo.id,
            batchId: this.batchInfo.name,
            otp: this.otp,
            approvedBy: 'skolarsuite'
          });
  
          if (res.data.code == 's200') {
            this.selected.forEach(el => {
              // this.desserts.splice(this.desserts.indexOf(el), 1);
              this.desserts[this.desserts.indexOf(el)].status = 'Approve';
              this.desserts[this.desserts.indexOf(el)].disabled = true;
            })
            Swal.fire({
              text: 'Approved successfully',
              icon: 'success'
            });
            // this.$router.push('/approve-admission-batch');
          } else {
            Swal.fire({
              text: res.data.message,
              icon: 'error',
              title: 'Oops..'
            });
  
          }
          this.dialog = false;
          this.otp = '';
  
        } catch (err) {
          console.log(err);
        }
      },
      async fetchBadgeData() {
        try {
          const res = await axios.post(`${this.$appUrl}/fetch-batch-admitted-student`, {
            programName: this.batchInfo.programName,
            session: this.batchInfo.session,
            id: this.batchInfo.id,
            batchId: this.batchInfo.name
          },{withCredentials: true}
          );
  
          console.log('Studnet BAtch', res);
          if (res.data.code == 's200') {
            const data = res.data.message;
            for (const el of data) {
              this.desserts.push({
                regnum: el.regNumber,
                department: el.department.name,
                faculty: el.faculty.name,
                from: el.entryPoint,
                id: el._id,
                name: `${el.firstName} ${el.surname}`,
                date: el.date_created.split('T')[0],
                disabled: el.status == 2 ? true : false,
                status: el.status == 2 ? 'Approved' : 'Not Approved'
              })
            }
  
            const approved = this.desserts.filter(el => el.status == 2);
            this.selected.push(...approved)
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  }
  </script>
  
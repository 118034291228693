<template>
  <div class="home">
        <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:18px;padding:5px;">Approve Admission Upload</span>
        </div>
    </div>
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">

  <v-card elevation="2" class="pa-4">
    <div class="pa-2 ">
      <h5>Uploaded Admission Batches</h5>
    </div>
    <v-row>
      <v-col cols="6">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
    <v-data-table v-model="selected" :headers="headers" item-key="name" :search="search" :items="desserts" show-select :single-select="false">
      <template v-slot:item.action="{ item }">
        <v-icon class="mr-2" @click="openBadge(item)">
          mdi-open-in-new
          <v-tooltip top color="default">
            <span>Open Badge uploads</span>
          </v-tooltip>
        </v-icon>
      </template>
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox :value="isSelected" :readonly="item.disabled" :disabled="item.disabled" @input="select($event)"></v-simple-checkbox>
      </template>
    </v-data-table>
    <!-- <v-btn class="mr-2" color="secondry" @click="genOtp">
      Generate otp
    </v-btn> -->
    <v-btn color="success" @click="upload">
      Approve
    </v-btn>
    <v-dialog v-model="dialog" width="500">
      <v-card class="pb-3">
        <v-card-title>
          Enter Otp key
        </v-card-title>
        <v-form class="px-5">
          <v-text-field v-model="otp" outlined label="Enter Code"></v-text-field>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="approve">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
  </div>

  </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2';
  import axios from 'axios';
  export default {
    data() {
      return {
        otp: '',
        dialog: false,
        selected: [],
        program:this.$activeSetting.data.program.slug,
         search: '',
        headers: [{
            text: 'Batch ID',
            value: 'name'
          },
          {
            text: 'Created By',
            value: 'created_by'
          },
          {
            text: 'Date Created',
            value: 'date_created'
          },
          {
            text: 'Status',
            value: 'status',
          },
          {
            text: 'Action',
            value: 'action'
          }
        ],
        desserts: [
        ]
      }
    },
    methods: {
      openBadge(item) {
        const param = window.btoa(JSON.stringify(item))
        console.log(param);
        this.$router.push(`/admissions/admissionbatch/${param}`)
      },
      async genOtp() {
        try {
          var url =this.$appUrl+'/gen-otp'
          const res = await axios.post(url, {
            createdBy: 'ac.enendu@unizik.edu.ng',
          });
          if (res.data.code == '00') {
            Swal.fire({
              icon: 'success',
              title: 'Otp send to phone',
              width: 400,
            })
          }
        } catch (error) {
          console.log(error);
        }
      },
      async upload() {
        try {
          if (this.selected.length !== 0) {
            this.dialog = true;
          } else {
            Swal.fire({
              text: 'Please select a batch',
              icon: 'info',
              title: 'Oops..'
            });
          }
        } catch (err) {
          console.log(err)
        }
      },
      async approve() {
        try {
          if (this.otp == '') return Swal.fire({
            text: 'Enter otp',
            icon: 'info'
          })
          const badgeData = this.selected;
          console.log(badgeData);
          var url = this.$appUrl+'/approve-badge'
          const res = await axios.post(url, {
            batches:badgeData,
            otp: this.otp,
            session:'2023', programName:this.program
          },{withCredentials: true});
          if (res.data.code == 's200') {
            this.selected.forEach(el => {
            //    this.selected.forEach(el => {
            //   this.desserts.splice(this.desserts.indexOf(el), 1);
            // })
              this.desserts[this.desserts.indexOf(el)].disabled = true;
              this.desserts[this.desserts.indexOf(el)].value = true;
            })
  
            Swal.fire({
              title: 'Approve Admission Upload',
              text: 'Approved successfully',
              icon: 'success'
            });
  
          } else {
            Swal.fire({
              text: res.data.message,
              title: 'Oops...',
              icon: 'error'
            });
  
          }
          this.dialog = false;
          this.otp = '';
  
        } catch (err) {
          console.log(err);
        }
      },
      async fetchAdmissionBadge() {
        
        try {
          const fdata = {
            session:"2023",program:this.program
          }
          var url = this.$appUrl+'/fetchadmissionbatch'
          const resdata = await axios.post(url,fdata,{withCredentials: true});
          console.log('ResDATA', resdata)
          const data = resdata.data.message;
          if (resdata.data.code !== 's200') return Swal.fire({
            title: 'Something went wrong! Pls relaod page',
            icon: 'info'
          })
          for (const el of data) {
            this.desserts.push({
              name: el.name,
              created_by: el.createdBy,
              date_created: el.dateCreated.split('T')[0],
              status: el.status === 2 ? 'Approved' : 'Not Approve',
              programName: el.programName,
              session: el.session,
              disabled: el.status === 2 ? true : false,
              id: el._id
            })
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
    beforeMount() {
      this.fetchAdmissionBadge();
    }
  }
  </script>
  
<template>
<div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc">
            <span style="color:#777; font-size:18px;padding:10px">Online Clearance</span>
        </div>
    </div>
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
        <v-alert style="color:#ff735e"> <strong>UNDERGRADUATE REGULAR 2023/2024 ADMISSION WINDOW</strong> <v-btn x-small style="margin-left:20px">Switch Admission Session</v-btn></v-alert>                        
        <v-row v-if="!foundStudent">
            <div style="margin:auto; padding:20px; margin-top:100px" class="col-md-5">
                <v-card elevation="3" height="300">
                    <v-card-title class="text-h7" style="color:#666">
                        Find Admited Student
                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>

                    <v-col cols="9" style="margin:auto;padding-top:45px" pa-4>
                        <v-text-field outlined label="Student Identity" v-model="studentIdentity" dense></v-text-field>
                        <v-btn color="primary" @click="find_admited_student" elevation='2' class="col-md-12" :disabled="studentIdentity==''">Find</v-btn>
                    </v-col>
                </v-card>
            </div>
        </v-row>
        <v-row>
            <div v-if="foundStudent" class="col-md-9" style="margin:auto;margin-top:100px">
                <v-card elevation="3">
                    <v-card-title class="text-h7" style="color:#666">
                        Student Admission Information
                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>

                    <div style="" class="pa-4">
                        <div class="card" style="background: #fff; width: 100%;">
                            <div style="background: #D6ECF5; height: 47px; padding: 10px; font-size: 15px; font-weight: 500;">Student Information</div>
                            <div class="py-2 px-5" style="background: #fff; width: 100%;">
                                <!-- Person information Fields -->
                                <div class="row">
                                    <div class="py-4 col-md-2">
                                        <img :src="studentInfo.message.biometric_img_link" width="150px">
                                    </div>
                                    <div class="py-4 col-md-10">
                                        <div class="row">
                                            <v-col cols="12" sm="4">
                                                <v-text-field v-model="studentInfo.message.surname" readonly label="Surname"></v-text-field>
                                            </v-col>

                                            <v-col cols="12" sm="4">
                                                <v-text-field v-model="studentInfo.message.name" readonly label="Name"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-text-field v-model="studentInfo.message.othername" readonly label="Othername"></v-text-field>
                                            </v-col>
                                        </div>
                                        <div class="row">
                                            <v-col cols="12" sm="4">
                                                <v-text-field v-model="studentInfo.message.gender" readonly label="Gender"></v-text-field>
                                            </v-col>

                                            <v-col cols="12" sm="4">
                                                <v-text-field v-model="studentInfo.message.dob" readonly label="Date of Birth"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" sm="4">
                                                <v-text-field v-model="studentInfo.message.lga" readonly label="Local Government"></v-text-field>
                                            </v-col>
                                        </div>

                                        <div class="row">
                                            <v-col cols="12" sm="4">
                                                <v-text-field v-model="studentInfo.message.state" readonly label="State Of Origin"></v-text-field>
                                            </v-col>

                                        </div>

                                        <!--  -->
                                    </div>
                                    <!-- Second Colum -->

                                </div>
                            </div>
                        </div>
                        <!-- Admission Information -->
                        <div style="background: #fff; margin-top: 20px;" class="mt-6">
                            <div style="background: #F2F0F1; height: 47px; padding: 10px; font-size: 15px; font-weight: 500;">Admission Information</div>
                            <div class="row">
                                <v-col cols="12" sm="4">
                                    <v-text-field v-model="studentInfo.message.regnumber" readonly label="Regnumber"></v-text-field>
                                </v-col>

                                <v-col cols="12" sm="4">
                                    <v-text-field v-model="studentInfo.message.faculty.name" readonly label="Faculty"></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field v-model="studentInfo.message.department.name" readonly label="Department"></v-text-field>
                                </v-col>
                            </div>
                            <div class="row">
                                <v-col cols="12" sm="4">
                                    <v-text-field v-model="studentInfo.message.entry_mode" readonly label="Entry Mode">{{studentInfo.message.entry_mode}}</v-text-field>
                                </v-col>

                            </div>
                        </div>
                        <!-- Result Information -->
                        <div style="margin-top: 20px;" class="row">
                            <div class="col-lg-6" v-for="(results, index) in allResultsfilter" :key="index">
                                <div style="background: #fff">
                                    <div style="background: #F2F0F1; height: 47px; padding: 10px; font-size: 15px; font-weight: 500;">Exam Details</div>
                                    <div class="px-5 py-3">
                                        Exam Name: {{results.examName}} - Exam No: {{results.examNumber}} - Exam Year : {{results.examYear}}
                                        <v-divider></v-divider>
                                        <div class="col-sm-10">
                                            <ul v-for="(subs, index) in results.subjects" :key="index">
                                                <li class="my-3 d-flex justify-content-between align-items-center">
                                                    <input type="text" :placeholder="subs.subjectName" disabled>
                                                    {{subs.grade}} </li>
                                            </ul>
                                            <!-- <FormConfirm /> -->
                                        </div>
                                        <div class="col-sm-6">
                                            <v-btn @click="addresult(index)">Remove This Result</v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 10px;" class="d-grid d-md-flex justify-content-md-end">
                            <button squared @click="cancel()" class="back_button mr-4 btn" type="button">Cancel Clearance</button>
                            <!-- <button squared @click="resetClearance" class="submit_button btn mx-2" type="button">Reset Clearance</button> -->
                            <v-dialog v-model="dialog" max-width="600px">
                                <template v-slot:activator="{ on, attrs }">
                                    <!-- <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        > -->
                                    <button squared class="submit_button btn" type="button" v-bind="attrs" v-on="on" @click="verifyresult()">Clear Student</button>

                                    <!-- <img src="../assets/finger.svg" width="100" style="cursor:pointer" v-bind="attrs" v-on="on"><br>
                                            Finger Biometric
                                            </v-btn> -->
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h5">Verify OTP</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-text-field v-model="hh" label="OTP"></v-text-field>

                                                    I Confirm that i have checked all the documents and they are in sync with the Data online <br><br>
                                                    <v-btn @click="clearStudent()">Clear Student</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>

                            </v-dialog>
                        </div>
                    </div>
                </v-card>
            </div>
        </v-row>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2';

export default {
    name: "Home",
    data: () => ({
        moder: "JAMB",
        studentIdentity: '',
        foundStudent: false,
        hh: '',
        allResultsfilter: [],
      noOfres: ''
    }),
    mounted() {
        this.hideActivityLoader();
        // remove modal on entry
        //hidePleaseWait()
    },
    methods: {
verifyresult(){
this.noOfres = this.allResultsfilter.length;
Swal.fire({
                                icon: 'info',
                                title: 'Number Of Results',
                                html: 'YOUA ARE ABOUT TO CLEAR THIS STUDENT WITH <strong>'+ noOfres +'</strong> RESULT(S). PLEASE DO NOT PROCEED IF THIS IS NOT RIGHT.',
                            })
},
        addresult(x){
        this.allResultsfilter.splice(x, 1);
        },
        getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return false;
        },
        ShowActivityLoader() {
            if (document.querySelector("#pleaseWaitDialog") == null) {
                var modalLoading = '<div class="modal" id="pleaseWaitDialog" data-backdrop="static" data-keyboard="false" role="dialog" style="margin-top:40vh">\
          <div class="modal-dialog" style="background-color:transparent;border:none">\
              <div class="modal-content" style="background-color:transparent; text-align:center;border:none">\
                  <div class="modal-body">\
                      <div class="spinner-border" role="status" style="color:#fff">\
  <span class="sr-only"></span>\
</div>\
<div style="color:#fff">Loading ...</div>\
                  </div>\
              </div>\
          </div>\
      </div>';
                $(document.body).append(modalLoading);
            }

            $("#pleaseWaitDialog").modal("show");
        },
        hideActivityLoader() {
            $("#pleaseWaitDialog").modal("hide");
        },

        parseJwt(token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        },
        async clearStudent() {
            let name = this.getCookie("-X_Zka");
            const jwtDecode = this.parseJwt(name)

            if (this.hh != '') {
                const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT + '/finalEclearance'
                const findAdmited = await axios({
                        method: 'POST',
                        url: url,
                        data: {
                            reg_number: this.studentIdentity,
                            staffId: jwtDecode.email,
                             noOfres:this.noOfres,
                            otp: this.hh
                        }
                    })
                    .then(response => {
                        if (response.data.code == '00') {
                            console.log(response.data);
                            Swal.fire({
                                icon: 'success',
                                title: 'Online Clearance',
                                text: 'You have sucessfully cleared '+this.studentIdentity,
                            })
                            this.foundStudent = false
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Online Clearance',
                                text: response.data.message,
                            })
                        }
                    })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Online Clearance',
                    text: 'Provide OTP',
                })
            }

        },
        async cancel() {
            this.foundStudent = false
        },
        async find_admited_student() {
            this.allResultsfilter = []
            this.showBiometric = false
            const url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT + '/find_admited'
            const findAdmited = await axios({
                    method: 'POST',
                    url: url,
                    data: {
                        year: '2023_2024',
                        entryData: this.studentIdentity
                    }
                })
                .then(response => {
                    const responses = response.data
                    if (responses.code == '00') {
                        if (responses.message.status == 5) {
                            this.studentInfo = responses
                            console.log(this.studentInfo);
                            this.foundStudent = true
                            responses.resulty.forEach((element, index) => {
                                if (element.subjects.length > 0) {
                                    this.allResultsfilter.push(element)
                                }
                            });
                        }
                        if (responses.message.status == 4) {
                            Swal.fire({
                                icon: 'info',
                                // title: 'Empty Form',
                                text: responses.message.surname + ' ' + responses.message.name + ' has not done facial biometric capture',
                            })
                        }
                        if (responses.message.status == 6) {
                            Swal.fire({
                                icon: 'info',
                                // title: 'Empty Form',
                                text: responses.message.surname + ' ' + responses.message.name + ' Has Been Cleared Online',
                            })
                        }
                        if (responses.message.status == 2) {
                            Swal.fire({
                                icon: 'error',
                                // title: 'Empty Form',
                                text: responses.message.surname + ' ' + responses.message.name + ' is not done finger biometric verification',
                            })
                        }
                        if (responses.message.status == 2) {
                            Swal.fire({
                                icon: 'error',
                                // title: 'Empty Form',
                                text: responses.message.surname + ' ' + responses.message.name + ' is not done with e-clearance form',
                            })
                        }
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Online Clearance',
                            text: 'Admitted Student Not Found',
                        })
                    }
                    if (response.data.code != '00') {
                        console.log(response.data);
                    }

                })

                .catch(e => {
                    console.log(e);
                    alert('Something went wrong')
                })
        }
    }
}
</script>

<style>

</style>
